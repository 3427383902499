import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { addedToCart, getPageLists, OFFLINE_CART, purchaseModal } from '../../actions/cartAction';
import {
   featuresToggle,
   getLatestUpdates,
   getSampleDocument,
   getTestimonialLists,
   getUpdatedDocuments,
   getUserBlogs,
   reqularityToggle,
} from '../../actions/homeAction';
import { getSettingsData, getSubscriptionLists } from '../../actions/subscriptionAction';
import { documentModalView, toggleOfferModal, getSlug } from '../../actions/uiAction';
import { open_iframe_modal } from '../../actions/documentAction';
import moment from 'moment';
import Moment from 'react-moment';
import WOW from '../../js/wow';
import 'react-owl-carousel2/lib/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import price_1 from '../../images/new-design/doc.png';
import refresh_1 from '../../images/new-design/refresh.png';
import feature1 from '../../images/new-design/feature1.png';
import feature2 from '../../images/new-design/feature2.png';
import feature3 from '../../images/new-design/feature3.png';
import feature4 from '../../images/new-design/feature4.png';
import feature5 from '../../images/new-design/feature5.png';
import mobilefeature from '../../images/new-design/mobile.png';
import mobilefeature2 from '../../images/new-design/mobile2.png';
import mobilefeature6 from '../../images/new-design/mobile6.png';
import policy_1 from '../../images/new-design/policy-1.png';
import policy_2 from '../../images/new-design/policy-2.png';
import policy_3 from '../../images/new-design/policy-3.png';
import policy_4 from '../../images/new-design/policy-4.png';
import record_1 from '../../images/new-design/record-1.png';
import record_2 from '../../images/new-design/record-2.png';
import record_3 from '../../images/new-design/record-3.png';
import record_4 from '../../images/new-design/record-4.png';
import client_1 from '../../images/new-design/client-1.png';
import client_2 from '../../images/new-design/client-2.png';
import client_3 from '../../images/new-design/client-3.png';
import client_4 from '../../images/new-design/client-4.png';
import client_7 from '../../images/new-design/client-7.png';
import client_8 from '../../images/new-design/client-8.png';
import update_bac from '../../images/new-design/update-bac.svg';
import c1 from '../../images/new-design/home-page/c-1.png';
import c2 from '../../images/new-design/home-page/c-2.png';
import c3 from '../../images/new-design/home-page/c-3.png';
import c4 from '../../images/new-design/home-page/c-4.png';
import tabicon from '../../images/new-design/home-page/user-manage.png';
import fixedphone from '../../images/new-design/home-page/fixed-phone.svg';
import hand from '../../images/new-design/hand.png';
import bussiness from '../../images/new-design/bussiness.png';
import { Helmet } from 'react-helmet-async';
import { policyReadNotifyModal } from '../../actions/employeePolicyAction';
import PolicyUnreadNotify from '../Common/PolicyUnreadNotifyModal';
import price_1_h from '../../images/new-design/small-icons/docs-icon.png';
import refresh_1_h from '../../images/new-design/small-icons/refresh-icon.png';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { subdomainRemindModalTogggle } from '../../actions/myProfileAction';
import ScrollTop from '../Common/ScroolTop';
import OfferModal from './OfferModal';
import IframeModal from '../Common/IframeModal';
import { openPopupWidget } from 'react-calendly';
import './index.css';

// New Images
import './index.scss';
import rating_img from '../../images/home_new_design/rating_img.svg';
import h_line from '../../images/home_new_design/h_line.svg';
import banner_img from '../../images/home_new_design/banner_img.svg';
import cb_1 from '../../images/home_new_design/cb_1.svg';
import cb_2 from '../../images/home_new_design/cb_2.svg';
import cb_3 from '../../images/home_new_design/cb_3.svg';
import cb_4 from '../../images/home_new_design/cb_4.svg';
import quality_management from '../../images/home_new_design/quality_management.svg';
import eo_tag from '../../images/home_new_design/eo_tag_emt.svg';
import sf_1 from '../../images/home_new_design/sf_1.svg';
import sf_2 from '../../images/home_new_design/sf_2.svg';
import sf_3 from '../../images/home_new_design/sf_3.svg';
import sf_4 from '../../images/home_new_design/sf_4.svg';
import sf_5 from '../../images/home_new_design/sf_5.svg';
import sf_6 from '../../images/home_new_design/sf_6.svg';
import feedback_survey from '../../images/home_new_design/feedback_survey.svg';
import pricing_notes from '../../images/home_new_design/pricing_notes.svg';
import pricing_reload from '../../images/home_new_design/pricing_reload.svg';
import app_store from '../../images/home_new_design/app_store.svg';
import play_store from '../../images/home_new_design/play_store.svg';
import ee_phone from '../../images/home_new_design/ee_phone.svg';
import start_your_business from '../../images/home_new_design/start_your_business.svg';
import hello_smiely from '../../images/home_new_design/hello_smiely.svg';

const TabPanel = (props) => {
   const { children, value, index, ...other } = props;
   return (
      <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
         {value === index && <h1>{children}</h1>}
      </div>
   );
};

const a11yProps = (index) => {
   return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
   };
};

const useStyles = makeStyles((theme) => ({
   root: {
      display: 'flex',
   },
   tabs: {
      borderRight: `0px solid ${theme.palette.divider}`,
   },
}));

const getCookie = (cname) => {
   let name = cname + '=';
   let decodedCookie = decodeURIComponent(document.cookie);
   let ca = decodedCookie.split(';');
   for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
         c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
         return c.substring(name.length, c.length);
      }
   }
   return '';
};

const Home = (props) => {
   const dispatch = useDispatch(),
      settingsData = useSelector((state) => state.subscriptionData.settingData),
      userBlogLists = useSelector((state) => state.homePageData.userBlogLists),
      subscriptionList = useSelector((state) => state.subscriptionData.subscriptionLists),
      selected_package = useSelector((state) => state.subscriptionData.selectedPackage),
      activeTab = useSelector((state) => state.homePageData.activeTab),
      activeTab2 = useSelector((state) => state.homePageData.activeTab2),
      documentList = useSelector((state) => state.homePageData.documentList),
      showDocumentModal = useSelector((state) => state.UIReducer.showDocumentModal),
      showDocument = useSelector((state) => state.UIReducer.showDocument),
      userDetails = useSelector((state) => state.myProfile.addViewData),
      showUnreadPolicyNotify = useSelector((state) => state.employeePolicyData.showUnreadPolicyNotify),
      offerShowModal = useSelector((state) => state.UIReducer.offerShowModal),
      purchasemodel = useSelector((state) => state.UIReducer.purchasemodel),
      offerSlugs = useSelector((state) => state.UIReducer.offerSlugs),
      viewIframeModal = useSelector((state) => state.documentData.viewIframeModal);
   const classes = useStyles();
   const [value, setValue] = React.useState(0);
   const [imgVal, setImage] = React.useState(1);

   const offerOn = settingsData && settingsData.offerStatus && settingsData.offerStatus === 1 ? true : false;
   const isPackageOffer = offerOn && settingsData.packagediscountStatus && settingsData.packagediscountStatus === 1 ? true : false;
   const isTrailOffer = offerOn && settingsData.trialStatus && settingsData.trialStatus === 1 ? true : false;

   const packageDiscount = isPackageOffer && settingsData.packageDiscountPercent ? settingsData.packageDiscountPercent : 0;
   const trailDiscount = isTrailOffer && settingsData.trialPeriod ? settingsData.trialPeriod : '';

   const packageValidDate = isPackageOffer && settingsData.offerExpiry ? settingsData.offerExpiry : '';
   const trailValidDate = isTrailOffer && settingsData.trialExpiry ? settingsData.trialExpiry : '';

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };
   const changeOfferModal = (val) => {
      dispatch(toggleOfferModal(val));
   };
   const getSlugArr = (arr) => {
      dispatch(getSlug(arr));
   };
   const backTo = () => {
      dispatch(purchaseModal());
   };
   useEffect(() => {
      new WOW().init();
      window.scrollTo(0, 0);
      dispatch(getSubscriptionLists(0, 4, 1, '', 'default'));
      dispatch(getTestimonialLists(0, 10));
      dispatch(getUpdatedDocuments(0, 4));
      dispatch(getSettingsData());
      dispatch(getUserBlogs());
      dispatch(getPageLists());
      dispatch(featuresToggle('1'));
      dispatch(getLatestUpdates(0, 5));
      dispatch(getSampleDocument());
   }, []);
   useEffect(() => {
      let x = getCookie('offerTime');
      if (offerOn) {
         if (x && (x === true || x === 'true')) {
            changeOfferModal(false);
         } else {
            const expires = new Date(Date.now() + 86400 * 1000).toUTCString();
            document.cookie = `offerTime = true; expires=${expires};`;
            changeOfferModal(true);
         }
      }
   }, [offerOn]);
   useEffect(() => {
      if (
         userDetails &&
         userDetails.role &&
         userDetails.role === 'employee' &&
         userDetails.assignedpolicyDocuments &&
         userDetails.assignedpolicyDocuments &&
         userDetails.assignedpolicyDocuments.length > 0
      ) {
         let unreadPolicy = [],
            allAssignPolicy = [...userDetails.assignedpolicyDocuments];
         unreadPolicy = allAssignPolicy.filter((e) => +e.status === 0);
         if (unreadPolicy && unreadPolicy.length > 0) {
            dispatch(policyReadNotifyModal(true));
         }
      }
      if (userDetails && userDetails.role && userDetails.role === 'company') {
         if (
            userDetails &&
            userDetails.companyDetails &&
            userDetails.companyDetails.subdomain &&
            userDetails.companyDetails.subdomain !== undefined &&
            userDetails.companyDetails.subdomain !== '' &&
            userDetails.companyDetails.subdomain !== null
         ) {
            dispatch(subdomainRemindModalTogggle(false));
         } else if (
            (userDetails &&
               userDetails.companyDetails &&
               userDetails.companyDetails.subdomain !== undefined &&
               String(userDetails.companyDetails.subdomain).length === 0) ||
            (userDetails && userDetails.companyDetails && userDetails.companyDetails.subdomain === undefined)
         ) {
            dispatch(subdomainRemindModalTogggle(true));
         }
      }
   }, [userDetails]);
   useEffect(() => {
      if (settingsData && settingsData.offerPackageIDS && settingsData.offerPackageIDS.length > 0) {
         getSlugArr(settingsData.offerPackageIDS);
      }
   }, [settingsData.offerPackageIDS]);

   const toggle = (tab) => {
      if (activeTab !== tab) dispatch(featuresToggle(tab));
   },
      toggle2 = (tab2) => {
         if (activeTab2 !== tab2) dispatch(reqularityToggle(tab2));
      },
      addToCart = (e, id, method, item) => {
         e.preventDefault();
         let printingFee = 0,
            renewalType = '';
         if (sessionStorage.getItem('userId') && sessionStorage.getItem('userId') !== '') {
            dispatch(
               addedToCart(
                  id,
                  sessionStorage.getItem('userId'),
                  props,
                  'subscription',
                  method,
                  selected_package && selected_package.length > 0 ? selected_package : [],
                  '',
                  '',
               ),
            );
         } else {
            dispatch(OFFLINE_CART(item, selected_package && selected_package.length > 0 ? selected_package : [], printingFee, renewalType, settingsData));
         }
      },
      setActiveClass = (data) => {
         sessionStorage.setItem('activeClass', data);
      },
      createMarkup = (description) => {
         return { __html: description || '' };
      },
      gotopage = (key) => {
         if (key === 'cqc') {
            props.history.push({ pathname: '/policies-procedures', state: { country: 'england' } });
            toggle2('1');
         } else if (key === 'ciw') {
            props.history.push({ pathname: '/policies-procedures', state: { country: 'wales' } });
            toggle2('2');
         } else if (key === 'cis') {
            props.history.push({ pathname: '/policies-procedures', state: { country: 'scotland' } });
            toggle2('3');
         } else if (key === 'ofsted') {
            props.history.push({ pathname: '/policies-procedures', state: { country: 'england', category: 'children-s-home' } });
            toggle2('4');
         } else if (key === 'rqia') {
            props.history.push({ pathname: '/policies-procedures', state: { country: 'northern-ireland' } });
            toggle2('5');
         }
      },
      changeImage = (id) => {
         setImage(id);
      },
      onHandleIframe = () => {
         var src = 'https://share.synthesia.io/embeds/videos/37060810-b05e-4a23-a66f-3e12b300135e';
         dispatch(open_iframe_modal(src));
      };

   let subscriptionLists = subscriptionList.length > 0 && subscriptionList.filter((item, i) => i <= 2 && item);
   let pdfFile = '';
   if (showDocument && showDocument !== undefined) {
      if (showDocument === +1) pdfFile = 'https://www.cloudoc.co.uk/uploads/Home_view_Documents/Data Protection Policy.pdf';
      if (showDocument === +2) pdfFile = 'https://www.cloudoc.co.uk/uploads/Home_view_Documents/Advocacy Policy.pdf';
      if (showDocument === +3) pdfFile = 'https://www.cloudoc.co.uk/uploads/Home_view_Documents/Health and Safety_ Risk Assessment and Control (Scotland) Policy.pdf';
      if (showDocument === +4) pdfFile = 'https://www.cloudoc.co.uk/uploads/documents/Admissions%20Policy-1640251539639.pdf';
      if (showDocument === +5) pdfFile = 'https://www.cloudoc.co.uk/uploads/documents/Reference%20Form-1640251737704.pdf';
      if (showDocument === +6)
         pdfFile = 'https://www.cloudoc.co.uk/uploads/documents/Assessment%20of%20a%20Service%20User%E2%80%99s%20Mental%20Capacity-1640251888394.pdf';
      if (showDocument === +7) pdfFile = 'https://www.cloudoc.co.uk/uploads/documents/Risk%20Assessment-1640251983627.pdf';
      if (showDocument === +8) pdfFile = 'https://www.cloudoc.co.uk/uploads/documents/Interview%20Form-1640252040980.pdf';
   }

   const settingss = {
      dots: false,
      slidesToShow: 5,
      centerMode: true,
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      speed: 2000,
      arrow: false,
      autoplaySpeed: 2000,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 3,
               infinite: true,
               dots: true,
            },
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               initialSlide: 2,
            },
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
            },
         },
      ],
   };
   const url = 'https://calendly.com/d/dpp-jhs-cxx/cloudoc-system-demo';
   const prefill = {};
   const pageSettings = {
      backgroundColor: 'ffffff',
      hideEventTypeDetails: false,
      hideLandingPageDetails: false,
      primaryColor: '00a2ff',
      textColor: '4d5055',
   };
   const utm = {
      utmCampaign: '',
      utmContent: '',
      utmMedium: '',
      utmSource: '',
      utmTerm: '',
   };
   const openPopup = () => openPopupWidget({ url, prefill, pageSettings, utm });
   // System Feature Section -- Start
   const [activeTabs, setActiveTabs] = useState(1);
   const tabs = [
      {
         id: 1,
         title: 'Document Quality Management',
         content: 'Improve your document workflow to ensure document quality is consistently maintained by tasking relevant users.',
         image: '/assets/images/home_new_design/systemFeature1.png',
      },
      {
         id: 2,
         title: 'Document Editor',
         content:
            'Empower your team with an intuitive document editor designed to simplify collaboration, ensure compliance, and maintain policy standards effortlessly.',
         image: '/assets/images/home_new_design/systemFeature2.png',
      },

      {
         id: 3,
         title: 'Updates Management',
         content:
            'Streamline your updates management to ensure policy accuracy and compliance by automatically tracking and notifying relevant users of changes',
         image: '/assets/images/home_new_design/systemFeature3.png',
      },
      {
         id: 4,
         title: 'User Management',
         content: 'Add users to the system to access, manage and control your documents. Allow custom permissions for each user and user groups.',
         image: '/assets/images/home_new_design/systemFeature4.png',
      },
      {
         id: 5,
         title: 'Document Sharing',
         content: 'You can make documents accessible publicly and/or to users/user groups based on flexible permissions you set.',
         image: '/assets/images/home_new_design/systemFeature5.png',
      },
      {
         id: 6,
         title: 'Employee Management',
         content:
            'Enhance employee accountability and compliance by assigning policies, tracking acknowledgements, and ensuring understanding with integrated quizzes—all in one streamlined platform.',
         image: '/assets/images/home_new_design/systemFeature6.png',
      },
   ];
   // System Feature Section -- End

   // FAQ Section -- Start
   const [accactiveTabIndex, accsetActiveTabIndex] = useState(0);
   const [expandedSubPanel, setExpandedSubPanel] = useState(0);
   const panels = [
      {
         title: 'System Features',
         content: [
            {
               subTitle: ' How often do policy templates get updated?',
               subContent:
                  'Our experienced team of policy writers continuously review all policies on ClouDoc to ensure they are up to date. We continuously monitor changes in regulations, advice and quality standards and make relevant changes to our policy templates.',
            },

            {
               subTitle: 'Can I share my documents with other people?',
               subContent:
                  'With ClouDoc, you can share your documents as PDFs with a link, via email or on a page on your website. Also, you can add users to your account and have them access specific folders and files on the application.',
            },

            {
               subTitle: 'Can I add my own documents on the system?',
               subContent:
                  'Yes. If you have subscribed for the document management system you can upload and create, save and manage your own documents on the system.',
            },
         ],
      },
      {
         title: 'Document Updates',
         content: [
            {
               subTitle: 'Can I download the documents in Word?',
               subContent:
                  'All documents in the editor can be downloaded as both MS Word and PDF. You can also edit using our online editor, which works similarly to MS Word.',
            },

            {
               subTitle: 'Does my company logo appear on the document?',
               subContent:
                  'Your company logo is uploaded on the settings section and it will appear on all documents, together with all the other relevant business information set up in the settings.',
            },

            {
               subTitle: 'Do I get notifications when documents are updated?',
               subContent:
                  'If you have purchased a document update subscription or you are within your first year of subscription, you get email notifications with download links. You also have access to new documents that are added into the document set you have purchased.',
            },
         ],
      },
      {
         title: 'Subscription & Pricing',
         content: [
            {
               subTitle: 'How much does it cost to renew my subscription?',
               subContent:
                  'The cost of renewal depends on your subscription and this differs for every subscription package. The renewal price is stated on the detail page for every subscription. It may also be a custom fee depending on the additional services in your subscription.',
            },

            {
               subTitle: ' What documents are included in my subscription?',
               subContent:
                  'You can view and also download the index of all the documents included in any of the document sets you buy. Check the package detail page for this information.',
            },
         ],
      },
      {
         title: 'General',
         content: [
            {
               subTitle: 'How much storage space can I get on the system?',
               subContent: 'Depending on your requirements you can purchase additional storage from 5GB.',
            },
            { subTitle: 'Can I add other people to access my documents?', subContent: 'Yes, you can add more users to your account to access your documents.' },
         ],
      },
   ];

   const toggleTab = (index) => {
      accsetActiveTabIndex((prevIndex) => (prevIndex === index ? null : index));
      setExpandedSubPanel(0);
   };

   const toggleSubPanel = (subIndex) => {
      setExpandedSubPanel((prevSubIndex) => (prevSubIndex === subIndex ? null : subIndex));
   };
   // FAQ Section -- End

   return (
      <div className="home_page">
         <Helmet>
            <title>Policies And Procedures For Supported Living | ClouDoc</title>
            <meta name="keyword" content="Policies And Procedures, Home Care, Supported Living, Semi Independent Living, Children Home" />
            <meta
               name="description"
               content="A Cloud document management system gives your business access to all file types, allowing you to be more efficient, productive & organized. Get started now"
               data-rh="true"
            />
            <link rel="canonical" href={window.location.href} />
         </Helmet>

         {/* Banner Section -- Start */}
         <div className="banner_section container">
            <div className="row">
               <div className="col-lg-6 col-md-12 colsm-12">
                  <h3>
                     Quality Assurance & <br /> Compliance{' '}
                     <span>
                        for your <br /> Care Business
                     </span>
                  </h3>
                  <p>
                     The most comprehensive and compliant management policies, procedures, documents and forms for the health and social care sector on a
                     convenient document management system.
                  </p>

                  <div className="rating_content">
                     <img src={rating_img} alt="rating" />
                     <figure>
                        <img
                           className="fivestarrating"
                           style={{ width: '70px' }}
                           src={process.env.PUBLIC_URL + '/assets/images/home_new_design/fivestarrating.svg'}
                        />
                        <figcaption>Rated 4.97/5 from over 50 reviews.</figcaption>
                     </figure>
                  </div>

                  <div className="book_demo_buy_now mt-4">
                     <div className="">
                        <p>Would you like to have a demo?</p>
                        <button onClick={() => openPopup()} className="book_a_demo_now">
                           Book A Demo Now
                        </button>
                     </div>
                     <img src={h_line} alt="line" style={{ height: '70px' }} />
                     <button onClick={() => props.history.push({ pathname: '/policies-procedures', state: { country: 'england' } })} className="buy_now banner" style={{ width: '270px' }}>
                        Buy Now
                     </button>
                  </div>
               </div>
               <div className="col-lg-6 col-md-12 colsm-12">
                  <div className="banner_img">
                     <img src={banner_img} width="100%" alt="Banner Images" />
                     <p>
                        <img src={hello_smiely} alt="hello smiely" />  Instantly downloadable, fully customised with your logo and company details!
                     </p>
                  </div>
               </div>
            </div>
         </div>
         {/* Banner Section -- End */}

         {/* Happy Client Section -- Start */}
         <div className="happy_client_section">
            <div className="container">
               <div className="happy_client">
                  <p>
                     Trusted by 1000+ <br /> Companies
                  </p>
                  <Slider {...settingss}>
                     <div className="happy-clients">
                        <img src={client_1} alt="client" />
                     </div>
                     <div className="happy-clients">
                        <img src={client_2} alt="client" />
                     </div>
                     <div className="happy-clients">
                        <img src={client_3} alt="client" />
                     </div>
                     <div className="happy-clients">
                        <img src={client_4} alt="client" />
                     </div>
                     <div className="happy-clients">
                        <img src={client_7} alt="client" />
                     </div>
                     <div className="happy-clients">
                        <img src={client_8} alt="client" />
                     </div>
                  </Slider>
               </div>
            </div>
         </div>
         {/* Happy Client Section -- End */}

         {/* Keep Your Compliant Section -- Start */}
         <div className="keep_your_complaint">
            <div className="container">
               <h4>
                  See how <span>Cloudoc</span> works to keep you compliant
               </h4>
               <div
                  className="col-lg-12 col-md-6 col-sm-12 col-xs-12 left-img intro_video_iframe"
                  style={{ position: 'relative', overflow: 'hidden', aspectRatio: '1920/1080', flex: '0 0 100%', maxWidth: '89.5%', margin: 'auto' }}
               >
                  <iframe
                     loading="lazy"
                     src="https://share.synthesia.io/embeds/videos/42e6dc3c-a12b-4a39-b1a6-3b3a27a4f3db"
                     title="SCORM Player"
                     allow="encrypted-media; fullscreen;"
                     allowFullScreen
                     style={{ border: 'none' }}
                  ></iframe>
               </div>
            </div>
         </div>
         {/* Keep Your Compliant Section -- End */}

         {/* Care Business Section -- Start */}
         <div className="care_business container">
            <h4>
               Exactly what you need for your <span>Care Business</span>
            </h4>
            <p>
               Stay compliant and worry-free with our reliable, comprehensive policies and management forms. Always up-to-date, quality <br /> checked and
               customisable to your business needs, yet affordable.
            </p>
            <div className="row">
               <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="care_business_card">
                     <img src={cb_2} alt="" />
                     <h5>Ready to Use Policies</h5>
                     <p>Access customised policies, complete with your company details and logo, ready for immediate use.</p>
                  </div>
               </div>
               <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="care_business_card">
                     <img src={cb_3} alt="" />
                     <h5>Up-to-Date Documents</h5>
                     <p>Stay ahead with documents that are always current and aligned with the latest regulations.</p>
                  </div>
               </div>
               <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="care_business_card">
                     <img src={cb_4} alt="" />
                     <h5>Flexible Pricing</h5>
                     <p>Choose a plan that fits your budget and scale with ease-flexibility designed for your business.</p>
                  </div>
               </div>
               <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="care_business_card">
                     <img src={cb_1} alt="" />
                     <h5>Well Written Policies</h5>
                     <p>All our policy templates are crafted by industry and legal experts</p>
                  </div>
               </div>
            </div>
         </div>
         {/* Care Business Section -- End */}

         {/* Quality Management And Compliance Section -- Start */}
         <div className="quality_management ">
            <div class="container">
               <div className="row">
                  <div className="col-lg-5 col-md-12 col-sm-12 qm_img">
                     <img src={quality_management} alt="Quality Management" />
                  </div>
                  <div className="col-lg-7  col-md-12 col-sm-12">
                     <h2>
                        Quality Management
                        <br />
                        and Compliance
                     </h2>
                     <p>
                        Stay compliant at the click of a button. ClouDoc ensures you meet the standards and regulations that are relevant to your business. Our
                        documents are always up to date and your business benefits from that. No need to research and scratch your head, our professionals do
                        that work for you so you focus on what’s important. We help you get ready for registration and inspections from regulators including
                        CQC, Ofsted , CIW, RQIA and Care Inspectorate Scotland.
                     </p>
                     <p>
                        {' '}
                        All documents are well-written and laid out with all the relevant parameters, as required by the regulators and as recommended by
                        quality standards agencies.
                     </p>
                     <div className="book_demo_buy_now mt-4 df" style={{justifyContent:'space-between',width:'70%',alignItems:'center'}}>
                        <div className="">
                           <p>Do you have any Questions ?</p>
                           <h6 style={{textAlign:'center'}}>0330 808 0050</h6>
                        </div>
                        <img src={h_line} alt="line" style={{ height: '70px' }} />
                        {/* <button onClick={() => props.history.push('/policies-procedures')} className="buy_now banner" style={{width:'270px'}}>
                        Buy Now
                     </button> */}
                        <button onClick={() => props.history.push('/policies-procedures')} className="">Buy Now</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* Quality Management And Compliance Section -- End */}

         {/* System Feature Section -- Start */}
         <div className="system_feature ">
            <div className="container">
               <h2>
                  System <span>Features</span>
               </h2>
               <p>
                  Our Cloud Document Management System is designed to help you manage all your company documents in one place: accessible from <br /> anywhere,
                  armed with an exhaustive list of features, including document editor, file uploads, folder management and more.
               </p>
               <div className="tab-container">
                  <div className="tab-headers">
                     {tabs.map((tab) => (
                        <button key={tab.id} className={`tab-header ${activeTabs === tab.id ? 'active' : ''}`} onClick={() => setActiveTabs(tab.id)}>
                           {tab.title}
                        </button>
                     ))}
                  </div>

                  <div className="tab-content">
                     {tabs
                        .filter((tab) => tab.id === activeTabs)
                        .map((tab) => (
                           <div key={tab.id} className="tab-panel">
                              <p>{tab.content}</p>
                              <span>
                                 <img src={tab.image} alt={`${tab.title} icon`} height={'439px'} width={'797px'} style={{ objectFit: 'contain' }} />
                              </span>
                           </div>
                        ))}
                  </div>
               </div>
               <div className="book_demo_buy_now">
                  <p>Would you like to have a demo?</p>
                  <button onClick={() => openPopup()} className="book_a_demo_now">
                     Book A Demo Now
                  </button>
               </div>
            </div>
         </div>
         {/* System Feature Section -- End */}

         {/* Exclusive Offer Section -- Start */}

         {offerOn ? (
            <div className="exclusive_offer ">
               <div className="container">
                  <h3 style={{ textAlign: 'center', color: '#fff', position: "relative", top: '-25px' }}>{settingsData?.offertitle}</h3>
                  <div className="row">
                     <div className="col-lg-6 col-md-6 col-sm-12 exclusive_offer_img">
                        <img src={eo_tag} width="100%" alt=" tag" />
                        <span className="valid">VALID TILL {moment(packageValidDate ? packageValidDate : trailValidDate).format('DD MMM YYYY')}</span>
                        <span className={`${isPackageOffer ? 'discount percent' : 'discount'}`}>
                           {isPackageOffer ? `${packageDiscount}%` : isTrailOffer ? `${trailDiscount[0]} Month` : ''}
                        </span>
                        {/* <span className="selected">{isPackageOffer ? 'ON SELECTED PACKAGES' : 'FREE TRIAL'}</span> */}
                     </div>
                     <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="row">
                           {isPackageOffer &&
                              offerSlugs &&
                              offerSlugs.length > 0 &&
                              offerSlugs.slice(0, 2).map((item, i) => {
                                 return (
                                    <>
                                       <div className="col-lg-6 col-md-12 col-sm-12">
                                          <div className="card_1">
                                             <h2>
                                                {isPackageOffer ? `${packageDiscount}%` : ''} <span>Offer</span>
                                             </h2>
                                             <p>{item.name ? item.name : ''}</p>
                                             <a href={`/subscription-detail/${item.slug}`}>
                                                View Packages<i class="fa-solid fa-angle-right"></i>
                                             </a>
                                          </div>
                                       </div>
                                    </>
                                 );
                              })}
                           {isTrailOffer ? (
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                 <div className="card_3">
                                    <p>Policy Updates And Documents Manangement System</p>
                                    <div className="h2_a">
                                       <h2>{trailDiscount[0]} Months Free</h2>
                                       <a href="/policies-procedures">
                                          Avail Now<i class="fa-solid fa-angle-right"></i>
                                       </a>
                                    </div>
                                 </div>
                              </div>
                           ) : null}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         ) : null}
         {/* Exclusive Offer Section -- End */}

         {/* Simple Document Form Section -- Start */}
         <div className="simple_document_form container">
            <h2>
               Sample <span className="doc">Documents</span> & <span className="form">Forms</span>
            </h2>
            <div className="row">
               <div className="col-lg-6 col-md-12 col-sm-12">
                  <h4>
                     <span className="doc">Policy</span> Documents
                  </h4>
                  <p>
                     Our policy templates are well-written and well-laid-out to meet your requirements as well as compliance needs. Whether you are registering
                     or keeping up with the sector changes, look no further.
                  </p>
                  <button className="buy_now">Download Free Policies</button>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="feedback_survey_img">
                     {/* <ul>
                            <li >
                                <a href onClick={() => dispatch(documentModalView(1))}>
                                    <img src={policy_1} alt="feedback Survey" />
                                    <p>Preview Document</p>
                                </a>
                            </li>
                            <li>
                                <a href onClick={() => dispatch(documentModalView(2))}>
                                    <img src={policy_2} alt="feedback Survey" />
                                    <p>Preview Document</p>
                                </a>
                            </li>
                            <li>
                                <a href onClick={() => dispatch(documentModalView(3))}>
                                    <img src={policy_3} alt="feedback Survey" />
                                    <p>Preview Document</p>
                                </a>
                            </li>
                            <li>
                                <a href onClick={() => dispatch(documentModalView(4))}>
                                    <img src={policy_4} alt="feedback Survey" />
                                    <p>Preview Document</p>
                                </a>
                            </li>
                        </ul> */}
                     <a className="feedback-Survey" href onClick={() => dispatch(documentModalView(1))}>
                        <img src="/assets/images/home_new_design/policyDoc-Icons-01.png" alt="feedback Survey" height={'184px'} width={'184px'} />
                        <p className="preview-text">Preview Document</p>
                     </a>
                     <a className="feedback-Survey" href onClick={() => dispatch(documentModalView(2))}>
                        <img src="/assets/images/home_new_design/policyDoc-Icons-02.png" alt="feedback Survey" height={'184px'} width={'184px'} />
                        <p className="preview-text">Preview Document</p>
                     </a>
                     <a className="feedback-Survey" href onClick={() => dispatch(documentModalView(3))}>
                        <img src="/assets/images/home_new_design/policyDoc-Icons-03.png" alt="feedback Survey" height={'184px'} width={'184px'} />
                        <p className="preview-text">Preview Document</p>
                     </a>
                     {/* <img src={feedback_survey} alt=" feedback Survey" />
                            <img src={feedback_survey} alt=" feedback Survey" />
                            <img src={feedback_survey} alt=" feedback Survey" /> */}
                  </div>
               </div>
            </div>
            <div className="row record_form">
               <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="feedback_survey_img">
                     <a className="feedback-Survey" href onClick={() => dispatch(documentModalView(5))}>
                        <img src="/assets/images/home_new_design/Icons-04.png" alt="feedback Survey" height={'184px'} width={'184px'} />
                        <p className="preview-text">Preview Document</p>
                     </a>
                     <a className="feedback-Survey" href onClick={() => dispatch(documentModalView(6))}>
                        <img src="/assets/images/home_new_design/Icons-05.png" alt="feedback Survey" height={'184px'} width={'184px'} />
                        <p className="preview-text">Preview Document</p>
                     </a>
                     <a className="feedback-Survey" href onClick={() => dispatch(documentModalView(7))}>
                        <img src="/assets/images/home_new_design/Icons-06.png" alt="feedback Survey" height={'184px'} width={'184px'} />
                        <p className="preview-text">Preview Document</p>
                     </a>
                  </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12">
                  <h4>
                     <span className="form">Record</span> Forms
                  </h4>
                  <p>
                     Our policy templates are well-written and well-laid-out to meet your requirements as well as compliance needs. Whether you are registering
                     or keeping up with the sector changes, look no further.
                  </p>
                  <button className="buy_now">Download Free Record Forms </button>
               </div>
            </div>
         </div>
         {/* Simple Document Form Section -- End */}

         {/* Packages and Pricing  Section -- Start */}
         <div className="packages_and_pricing ">
            <div className="container">
               <h2>
                  <span>Packages</span> and Pricing
               </h2>
               <p>
                  Our pricing is designed to suit your needs, you can purchase a one off documents license or get a discounted license <br /> price when you
                  purchase an updates subscription and or document management system.
               </p>
               <button className="view_all" onClick={() => props.history.push('/policies-procedures')}>
                  View All <i className="fa fa-angle-right" aria-hidden="true"></i>
               </button>

               <div className="row">
                  {subscriptionLists &&
                     subscriptionLists.length > 0 &&
                     subscriptionLists.map((item, i) => (
                        <div className="col-lg-3 col-md-6 col-sm-12">
                           <div className="before_add_to_cart">
                              {/* <div className="pricing_1"> */}
                              <h6>{item.name}</h6>
                              <hr />
                              <div className="para_img">
                                 <div>
                                    <h6>Document Includes</h6>
                                    <p>{item.files}</p>
                                 </div>
                                 <img src={pricing_notes} alt="Pricing Notes" />
                              </div>
                              <div className="para_img">
                                 <p>100</p>
                                 <img src={pricing_reload} alt="Pricing Reload" />
                              </div>
                              <hr />
                              <p className="form">From</p>
                              <p className="amt">
                                 <span>£</span>
                                 {settingsData.discountStatus === 1
                                    ? Number(item.licenseFee - (item.licenseFee * settingsData.yearlyDiscount) / 100).toFixed(2)
                                    : item.licenseFee}
                                 {/* <span>.00</span> */}
                              </p>
                              <hr />
                              <button onClick={(e) => addToCart(e, item._id, 'add-to-cart', item)} className="atc">
                                 Add To Cart <i class="fa-solid fa-cart-shopping"></i>
                              </button>
                              <button onClick={() => props.history.push(`/subscription-detail/${item.slug}`)} className="view_details">
                                 View Details
                              </button>
                           </div>
                        </div>
                     ))}
               </div>
            </div>
         </div>
         {/* Packages and Pricing  Section -- End */}

         {/* Latest Documents Updates Section -- Start */}
         <div className="latest_documents_updates ">
            <div className="container">
               <div className="title_link">
                  <h2>
                     <span>Latest</span> Documents Updates
                  </h2>
                  <button className="view_all" onClick={() => props.history.push('/document-updates')}>
                     View All<i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
               </div>
               <p>
                  We review documents on our platform regularly to meet legal, regulators/compliance, quality improvement needs and <br /> more. View our latest
                  updates below
               </p>
               <div className="row">
                  {documentList &&
                     documentList.length > 0 &&
                     documentList.map((item, i) => (
                        <div className="col-lg-4 col-md-6 col-sm-12">
                           <div className="latest_doc">
                              <h6>{item.filename}</h6>
                              <p>Date Updated : {moment(item.updatedAt).format('DD MMM YYYY')}</p>
                              <div className="sr">
                                 <p>Special Requirements - Families and Children</p>
                                 <p>SR08</p>
                              </div>
                           </div>
                        </div>
                     ))}
               </div>
            </div>
         </div>
         {/* Latest Documents Updates Section -- End */}

         {/* News Updates Section -- Start */}
         <div className="new_updates ">
            <div className="container">
               <h2>
                  <span>New</span> Updates
               </h2>
               <a
                  className="view_all"
                  href={
                     settingsData && settingsData.blog_url && settingsData.blog_url !== '' && settingsData.blog_url !== undefined ? settingsData.blog_url : '#'
                  }
                  target="_self"
               >
                  <button>
                     View All <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
               </a>
               <div className="row">
                  {userBlogLists &&
                     userBlogLists.length > 0 &&
                     userBlogLists.map((item, i) => (
                        <div className="col-lg-3 col-md-6 col-sm-12" key={i}>
                           {item.featured_media ? (
                              <a href={item.link} target="_self">
                                 <img
                                    src={
                                       item._embedded['wp:featuredmedia'][0] &&
                                          item._embedded['wp:featuredmedia'][0].media_details.sizes['large'] &&
                                          item._embedded['wp:featuredmedia'][0].media_details.sizes['large'] &&
                                          item._embedded['wp:featuredmedia'][0].media_details.sizes['large'] !== undefined &&
                                          item._embedded['wp:featuredmedia'][0].media_details.sizes['large'].source_url
                                          ? item._embedded['wp:featuredmedia'][0].media_details.sizes['large'].source_url
                                          : item._embedded['wp:featuredmedia'][0].source_url
                                             ? item._embedded['wp:featuredmedia'][0].source_url
                                             : update_bac
                                    }
                                 />
                              </a>
                           ) : null}
                           <p className="posted_date">
                              Posted On: <Moment format="DD MMM YYYY">{new Date(item.date ? item.date : '')}</Moment>
                           </p>
                           <h3>
                              <a href={item.link} target="_self" dangerouslySetInnerHTML={{ __html: item.title.rendered }} />
                           </h3>
                           {item.content.rendered ? <div className="up-content" dangerouslySetInnerHTML={{ __html: item.content.rendered }} /> : null}
                           <span className="read_more">
                              <a href={item.link} target="_self">
                                 Read More <i className="fa fa-angle-right" aria-hidden="true"></i>
                              </a>
                           </span>
                        </div>
                     ))}
               </div>
            </div>
         </div>
         {/* News Updates Section -- End */}

         {/* Exclusive App for Employees Section -- Start */}
         <div className="exclusive_exployee container">
            <div className="row aic">
               <div className="col-lg-6 col-md-12 col-sm-12">
                  <h2>
                     Exclusive App for <span>Employees</span>
                  </h2>
                  <p>
                     The Cloudoc Employee App is designed to support compliance and streamline essential tasks for employees. With mobile access, employees can
                     view important documents, read and acknowledge workplace policies, and stay compliant anytime, anywhere. The app also makes training
                     simple, allowing employees to complete modules and quizzes directly from their devices. By providing an efficient way to manage compliance
                     tasks, the Cloudoc Employee App ensures employees are always informed and empowered to meet workplace requirements with ease.
                  </p>
                  <div className="ply_app_str">
                     <img src={play_store} alt="Play Store" style={{cursor:'pointer'}} onClick={()=> window.location.href='https://play.google.com/store/apps/details?id=com.cloudoc'}/>
                     <img src={app_store} alt="App Store" style={{cursor:'pointer'}} onClick={()=> window.location.href='https://apps.apple.com/us/app/cloudoc/id6504866507'} />
                  </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* <img src={ee_phone} alt="Phone" /> */}
                  <img width="100%" src="/assets/images/home_new_design/Mobile.svg" alt="Phone" className='ee_phone' />
               </div>
            </div>
         </div>
         {/* Exclusive App for Employees Section -- End */}

         {/* FAQ Section -- Start -- Line 393 for content uploaded */}
         {/* <div className="faq container">
                <h2>
                    <span>Frequently</span> Asked Questions
                </h2>
                <div className="accordion-with-separate-titles">
                    <div className="titles-section">
                        {panels.map((panel, index) => (
                            <button
                                key={index}
                                className={`title-button ${accactiveTabIndex === index ? "active" : ""}`}
                                onClick={() => toggleTab(index)}
                            >
                                {panel.title}
                            </button>
                        ))}
                    </div>
                    <div className="accordion">
                        {accactiveTabIndex !== null && (
                            <div className="accordion-content row">
                                {panels[accactiveTabIndex].content.map((subPanel, subIndex) => (
                                    <div className="col-lg-6 col-md-6 col-sm-12" key={subIndex}>
                                        <div
                                            className={`nested-accordion-item ${expandedSubPanel === subIndex ? "active" : ""
                                                }`}
                                        >
                                            <button
                                                className="nested-accordion-header"
                                                onClick={() => toggleSubPanel(subIndex)}
                                            >
                                                {subPanel.subTitle}
                                            </button>
                                            <div
                                                className={`nested-accordion-content ${expandedSubPanel === subIndex ? "show" : ""
                                                    }`}
                                            >
                                                {expandedSubPanel === subIndex && <p>{subPanel.subContent}</p>}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div> */}
         {/* FAQ Section -- End */}

         {/* Start Your Business Section -- Start */}
         <div className="start_your_business mt-5">
            <div className="container">
               <div className="row aic">
                  <div className="col-lg-6 col-md-12 col-sm-12 left_side">
                     <img src={start_your_business} alt="start your business" />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                     <h2>
                        Start Your Business Journey With <br /> Cloudoc
                     </h2>
                     <p>
                        Specialising in essential services, it offers dedicated support for CQC and Ofsted registration, ensuring your business has a solid
                        foundation. Discover tailored strategies to build your venture.
                     </p>
                     <button onClick={() => openPopup()} className="book_a_demo_now">
                        Book A Demo Now
                     </button>
                  </div>
               </div>
            </div>
         </div>
         {/* Start Your Business Section -- End */}

         {<div className=".progress-ani"></div>}
         <div className="fixed-phone">
            <div className="fixed-phone-sec">
               <a href="tel:0330 808 0050">
                  <img src={fixedphone} />
               </a>
            </div>
         </div>
         <ScrollTop />
         <Modal isOpen={showDocumentModal} toggle={() => dispatch(documentModalView())} id="document-view">
            <ModalHeader toggle={() => dispatch(documentModalView())}>PREVIEW DOCUMENT</ModalHeader>
            <ModalBody>
               <iframe src={pdfFile} frameBorder="0" scrolling="auto" height="100%" width="100%" />
            </ModalBody>
            <ModalFooter>
               <Button onClick={() => dispatch(documentModalView())}>Cancel</Button>
            </ModalFooter>
         </Modal>
         {showUnreadPolicyNotify && <PolicyUnreadNotify history={props.history} />}
         {!purchasemodel && offerShowModal ? (
            <OfferModal
               offerShowModal={offerShowModal}
               toggleOfferModal={changeOfferModal}
               settingsData={settingsData}
               getSlug={getSlugArr}
               offerSlugs={offerSlugs}
               offerOn={offerOn}
               isPackageOffer={isPackageOffer}
               isTrailOffer={isTrailOffer}
               packageDiscount={packageDiscount}
               trailDiscount={trailDiscount}
               packageValidDate={packageValidDate}
               trailValidDate={trailValidDate}
            />
         ) : null}
         {purchasemodel ? (
            <Modal isOpen={purchasemodel} className="documentlist-home success-modal" centered={true} toggle={backTo}>
               <ModalBody>
                  <p className="docuListHomeP">Thank You for Your Purchase!</p>
                  <p className="docuListHomeP2">Your documents are ready and waiting for you. Let’s make them uniquely yours.</p>
                  <p className="docuListHomeP3" style={{ textAlign: 'left', fontWeight: '600' }}>
                     Next Steps:
                  </p>
                  <p style={{ textAlign: 'justify' }}>
                     Add your company logo and details to give your documents a professional touch.
                     <div className="pur-success" style={{ alignItems: 'center', display: 'flex', marginTop: '8px' }}>
                        <button className="pur-success-button" onClick={() => props.history.push('/myDocuments')}>
                           Customise Your Documents
                        </button>
                     </div>
                     <br />
                     Access and download your documents now to start using them right away.
                     <div className="pur-success" style={{ alignItems: 'center', display: 'flex', marginTop: '8px' }}>
                        <button className="pur-success-button" onClick={() => props.history.push('/myDocuments')}>
                           View & Download Documents&nbsp;
                        </button>
                     </div>
                     <br />
                     Find more documents to complete your collection and meet your business requirements.
                     <div className="pur-success" style={{ alignItems: 'center', display: 'flex', marginTop: '8px' }}>
                        <button className="pur-success-button" onClick={backTo}>
                           Browse More Documents&nbsp;
                        </button>
                     </div>
                     <br />
                     If you have any questions or need further assistance, our team is here to help. Thank you for choosing Cloudoc!
                  </p>
               </ModalBody>
            </Modal>
         ) : null}

         {viewIframeModal === true ? <IframeModal /> : null}
      </div>
   );
};

export default Home;
