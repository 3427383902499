import * as actionTypes from '../actions/types.js';

export default (state = {
    formData: {}, active_subs: '', activeTab: "1", Comp_details: 0, dismiss_subs: false, dismiss_comp_details: false,
    forgotUser: "", path: "", docManagementStatus: '',showOTPModal:false, docUpdateStatus: '', employeeSubscription: '', subscription_status: '', companyDetails: {}, licenseStatus: '', hashPathName: ''
}, action) => {

    switch (action.type) {

        case actionTypes.ADMIN_LOGIN:
            return {
                ...state,
                formData: { ...action.payload }
            };
        case actionTypes.SHOW_SIGNUP:
            return {
                ...state,
                showSignup: !state.showSignup
            };
        case actionTypes.TOP_POP_ACTIVE_SUBS:
            return {
                ...state,
                active_subs: action.payload,
                docUpdateStatus: action.docUpdateStatus,
                docManagementStatus: action.docManagementStatus,
                employeeSubscription: action.employeeSubscription,
                subscription_status: action.status === 'active' ? 'active' : 'in-active',
                licenseStatus: action.licenseStatus
            };
        case actionTypes.TOP_POP_COMP_DETAILS:
            return {
                ...state,
                Comp_details: action.payload
            };
        case actionTypes.DISMISS_SUBS:
            return {
                ...state,
                dismiss_subs: action.payload
            };
        case actionTypes.DISMISS_COMP_DETAILS:
            return {
                ...state,
                dismiss_comp_details: action.payload
            };
        case actionTypes.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload,
                formData: {}
            }
        case actionTypes.AUTH_MODAL:
            return {
                ...state,
                showMainModal: !state.showMainModal,
                showLoginModal: true,
                showForgotModal: false,
                showOptModal: false,
                showNewPwdModal: false,
                activeTab: "1",
                path: action.payload
            }
        case actionTypes.SIGN_IN_MODAL:
            return {
                ...state,
                showLoginModal: !state.showLoginModal,
                activeTab: "1",
            }
        case actionTypes.OTP_MODAL:
            return {
                ...state,
                showOTPModal: !state.showOTPModal,
            }
        case actionTypes.FORGOT_MODAL:
            return {
                ...state,
                showForgotModal: !state.showForgotModal,
            }
        case actionTypes.OPT_MODAL:
            return {
                ...state,
                showOptModal: !state.showOptModal,
            }
        case actionTypes.NEW_PASSWORD_MODAL:
            return {
                ...state,
                showNewPwdModal: !state.showNewPwdModal,
            }
        case actionTypes.CLEAR_FORM_DATA:
            return {
                ...state,
                formData: {}
            };
        case actionTypes.FORGOT_PWD_USER:
            return {
                ...state,
                forgotUser: action.payload
            };
        case actionTypes.COMPANY_CREDETIALS:
            return {
                ...state,
                companyDetails: action.payload
            };
        case actionTypes.HASH_URL:
            return {
                ...state,
                hashPathName: action.payload
            };
        default:
            return state;
    }
};
