export const ADD_FOLDER_NAME = 'ADD_FOLDER_NAME';
export const CLEAR_FOLDER_NAME = 'CLEAR_FOLDER_NAME';
export const CLEAR_EDIT_DOCS_DATA = 'CLEAR_EDIT_DOCS_DATA';
export const ADD_FILE_NAME = 'ADD_FILE_NAME';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const GET_ALL_DOCUMENT = 'GET_ALL_DOCUMENT';
export const CLEAR_DOCUMENT = 'CLEAR_DOCUMENT';
export const GET_DOCUMENT_BY_ID = 'GET_DOCUMENT_BY_ID';
export const RENAME_MODAL_VIEW = 'RENAME_MODAL_VIEW';
export const FILE_EDIT_MODAL_VIEW = 'FILE_EDIT_MODAL_VIEW';
export const DOCUMENT_DELETE_MODEL = 'DOCUMENT_DELETE_MODEL';
export const FILE_DELETE_MODEL = 'FILE_DELETE_MODEL';
export const SELECTED_FILE = 'SELECTED_FILE';
export const SELECTED_DETAILS = 'SELECTED_DETAILS';
export const TOGGLE_VIEW_STATE = 'TOGGLE_VIEW_STATE';
export const MODAL_VIEW = 'MODAL_VIEW';
export const PREVIEW_MODAL_VIEW = 'PREVIEW_MODAL_VIEW';
export const LOGOUT_MODAL_VIEW = 'LOGOUT_MODAL_VIEW';
export const NOTIFICATION_VIEW = 'NOTIFICATION_VIEW';
export const LIST_MODAL_VIEW = 'LIST_MODAL_VIEW';
export const SELECT_TEMPLATE = 'SELECT_TEMPLATE';
export const UPDATE_LIST = 'UPDATE_LIST';
export const LOGOUT = 'LOGOUT';
export const CLICKED_DOCUMENT = 'CLICKED_DOCUMENT';
export const CLEAR_CLICKED_DOCUMENT = 'CLEAR_CLICKED_DOCUMENT';
export const PATH_CHANGING = 'PATH_CHANGING';
export const ADD_CLICKED_DOCUMENT = 'ADD_CLICKED_DOCUMENT';
export const DELETE_DOCUMENT__REMOVE = 'DELETE_DOCUMENT__REMOVE';
export const DELETE_FILE__REMOVE = 'DELETE_FILE__REMOVE';
export const UPDATE_RENAME_MODAL = 'UPDATE_RENAME_MODAL';
export const RECENT_FILES = 'RECENT_FILES';
export const UPDATE_RECENT_FILES = 'UPDATE_RECENT_FILES';
export const UPDATE_FILE_EDIT_MODAL = 'UPDATE_FILE_EDIT_MODAL';
export const REFERENCE_DOCUMENT_MODAL = 'REFERENCE_DOCUMENT_MODAL';
export const REFERENCE_OPTION = 'REFERENCE_OPTION';
export const SELECTED_REF_DOC = 'SELECTED_REF_DOC';
export const REF_DELETE_MODAL = 'REF_DELETE_MODAL';
export const BACKUP_MODAL = 'BACKUP_MODAL';
export const BACKUP_FILE_DATA = 'BACKUP_FILE_DATA';
export const VIEW_BACKUP_MODAL = 'VIEW_BACKUP_MODAL';
export const USE_TEMPLATE_MODAL = 'USE_TEMPLATE_MODAL';
export const MYFILES_ALL_DATA = 'MYFILES_ALL_DATA';
export const SELECTED_MYFILE = 'SELECTED_MYFILE';
export const CREATE_FOLDER_MODAL = 'CREATE_FOLDER_MODAL';
export const MYFILES_FOLDERS_LIST = 'MYFILES_FOLDERS_LIST';
export const ADD_MYFILES_FOLDER = 'ADD_MYFILES_FOLDER';
export const CREATE_MYFILES_FOLDER_MODAL = 'CREATE_MYFILES_FOLDER_MODAL';
export const MAIN_SUBFOLDERS = 'MAIN_SUBFOLDERS';
export const SUB_ONE_SUBFOLDERS = 'SUB_ONE_SUBFOLDERS';
export const SUB_TWO_SUBFOLDERS = 'SUB_TWO_SUBFOLDERS';
export const SUB_THREE_SUBFOLDERS = 'SUB_THREE_SUBFOLDERS';
export const SUB_FOUR_SUBFOLDERS = 'SUB_FOUR_SUBFOLDERS';
export const SUB_FIVE_SUBFOLDERS = 'SUB_FIVE_SUBFOLDERS';
export const MYFILES_ROOT_ARRAY = 'MYFILES_ROOT_ARRAY';
export const MYFILES_RENAME_MODAL = 'MYFILES_RENAME_MODAL';
export const MYFILES_RENAME_FOLDERNAME = 'MYFILES_RENAME_FOLDERNAME';
export const MYFILES_FILE_CREATE_MODAL = 'MYFILES_FILE_CREATE_MODAL';
export const MYFILE_FILE_OPTIONS = 'MYFILE_FILE_OPTIONS';
export const MYFILE_MOVE_MODAL = 'MYFILE_MOVE_MODAL';
export const MYFILE_FOLDER_STRCTURE = 'MYFILE_FOLDER_STRCTURE';
export const MYFILES_EDITOR_FILE = 'MYFILES_EDITOR_FILE';
export const TOGGLE_MYFILES_SEND_MODAL = 'TOGGLE_MYFILES_SEND_MODAL';
export const MYFILES_RECIPIENTS_MODAL = 'MYFILES_RECIPIENTS_MODAL';
export const MYFILES_EDITOR_CONTENT = 'MYFILES_EDITOR_CONTENT';
export const TOGGLE_MYFILES_VIEW_MODAL = 'TOGGLE_MYFILES_VIEW_MODAL';
export const TOGGLE_MYFILES_SIGN_VIEW_MODAL = 'TOGGLE_MYFILES_SIGN_VIEW_MODAL';
export const TOGGLE_MYFILE_DELETE_MODAL = 'TOGGLE_MYFILE_DELETE_MODAL';
export const MYFILE_FOLDER_MOVE = 'MYFILE_FOLDER_MOVE';
export const BUTTON_CHANGE_MODAL = 'BUTTON_CHANGE_MODAL';
export const DRAGGING_ID = 'DRAGGING_ID';
export const DROP_ID = 'DROP_ID';
export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL';
export const SENT_LIST = 'localVariable';
export const MYFILES_ACTIVE_PAGE = 'MYFILES_ACTIVE_PAGE';
export const UPLOAD_FILES = 'UPLOAD_FILES';
export const PDF_VIEWER = 'PDF_VIEWER';

export const MYFILES_SHARED_WITHME_DATA = 'MYFILES_SHARED_WITHME_DATA';
export const MYFILES_WAITING_DATA = 'MYFILES_WAITING_DATA';
export const MYFILES_VIEWED_DATA = 'MYFILES_VIEWED_DATA';
export const MYFILES_FINALIZED_DATA = 'MYFILES_FINALIZED_DATA';
export const MYFILES_TOTALSENT_DATA = 'MYFILES_TOTALSENT_DATA';
export const MYFILES_DELETED_FOLDER = 'MYFILES_DELETED_FOLDER';
export const MYFILES_DELETED_FILE = 'MYFILES_DELETED_FILE';
export const MYFILES_TIMELINE_FILE = 'MYFILES_TIMELINE_FILE';


/* saved documents */
export const CLEAR_SAVED_DOCS = 'CLEAR_SAVED_DOCS';
export const SEARCH_VAL_SAVED_DOCS = 'SEARCH_VAL_SAVED_DOCS';
export const LIST_SAVED_DOCS = 'LIST_SAVED_DOCS';
export const SET_ATTR_FOR_SAVED_DOCS = 'SET_ATTR_FOR_SAVED_DOCS';
export const EDIT_VIEW_SAVED_DOCS = 'EDIT_VIEW_SAVED_DOCS';
export const SET_SAVED_DOCS_EDITOR_CONTENT = 'SET_SAVED_DOCS_EDITOR_CONTENT';
export const POP_FOR_SAVED_DOCS_NAME = 'POP_FOR_SAVED_DOCS_NAME';
export const SAVED_DOCS_SELECTED_FILE = 'SAVED_DOCS_SELECTED_FILE';
export const SHOW_POP_FR_SAVED_DOCS = 'SHOW_POP_FR_SAVED_DOCS';
export const CLEAR_SAVED_DOCS_ADD_DATA = 'CLEAR_SAVED_DOCS_ADD_DATA';
export const GET_ORIGINAL_FILE_DATA = 'GET_ORIGINAL_FILE_DATA';
export const SET_EDITOR_CONTENT_FOR_SAVDD_DOCS = 'SET_EDITOR_CONTENT_FOR_SAVDD_DOCS';
export const SET_DOCS_EDITED_DATA_BY_ID = 'SET_DOCS_EDITED_DATA_BY_ID';
export const EDIT_SAVED_DOCS_NAME_POP = 'EDIT_SAVED_DOCS_NAME_POP';


// Admin Login
export const ADMIN_LOGIN = 'ADMIN_LOGIN';
export const AUTHENTICATION = 'AUTHENTICATION';

//Settings
export const SETTING_ADD = 'SETTING_ADD';

// Template
export const TEMPLATE_LIST = 'TEMPLATE_LIST';
export const CLEAR_TEMPLATE_LIST = 'CLEAR_TEMPLATE_LIST';
export const EDIT_VIEW = 'EDIT_VIEW';
export const ADD_VIEW = 'ADD_VIEW';
export const CLEAR_ADD_VIEW = 'CLEAR_ADD_VIEW';
export const TEMPLATE_EDIT = 'TEMPLATE_EDIT';
export const TEMPLATE_DELETE_MODAL = 'TEMPLATE_DELETE_MODAL';
export const ERROR_MSG = 'ERROR_MSG';
export const ERROR_MSG_CLEAR = 'ERROR_MSG_CLEAR';
export const TEMPLATE_VIEW = "TEMPLATE_VIEW";
export const TEMPLATE_PREVIEW = "TEMPLATE_PREVIEW";

// COMPANY
export const COMPANY_LIST = 'COMPANY_LIST';
export const CLEAR_COMPANY_LIST = 'CLEAR_COMPANY_LIST';
export const EDIT_VIEW_COMPANY = 'EDIT_VIEW_COMPANY';
export const COMPANY_DELETE_MODAL = 'COMPANY_DELETE_MODAL';
export const ADD_VIEW_COMPANY = 'ADD_VIEW_COMPANY';
export const IMAGE_ADD_VIEW_COMPANY = 'IMAGE_ADD_VIEW_COMPANY';
export const CLEAR_ADD_VIEW_COMPANY = 'CLEAR_ADD_VIEW_COMPANY';

// SUBSCRIPTION
export const SUBSCRIPTION_LIST = 'SUBSCRIPTION_LIST';
export const CLEAR_SUBSCRIPTION = 'CLEAR_SUBSCRIPTION';
export const SUBSCRIPTION_DELETE_MODAL = 'SUBSCRIPTION_DELETE_MODAL';
export const SUBSCRIPTION_EDIT_VIEW = 'SUBSCRIPTION_EDIT_VIEW';
export const SUBSCRIPTION_PERIOD = 'SUBSCRIPTION_PERIOD';
export const SUBSCRIPTION_ADD_VIEW = 'SUBSCRIPTION_ADD_VIEW';
export const CLEAR_SUBSCRIPTION_ADD_VIEW = 'CLEAR_SUBSCRIPTION_ADD_VIEW';
export const EMPLOYEE_SELECT_PLAN = 'EMPLOYEE_SELECT_PLAN';


// Delete Modal
export const GET_DASHBOARD = 'GET_DASHBOARD';

export const ADD_MYPROFILE = 'ADD_MYPROFILE';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const DOCUMENT_SETTINGS = 'DOCUMENT_SETTINGS';
export const SET_COLOR = 'SET_COLOR';
export const CHOOSE_TEMPLATE = 'CHOOSE_TEMPLATE';
export const VIEWTEMP = 'VIEWTEMP';
export const DISABLED_COMP_NAME = 'DISABLED_COMP_NAME';
export const DISABLED_COMP_REGNO = 'DISABLED_COMP_REGNO';
export const DISABLED_COMP_TRADING = 'DISABLED_COMP_TRADING';

export const TEMPLATE_IMAGE = 'TEMPLATE_IMAGE';

export const SHOW_SIGNUP = 'SHOW_SIGNUP';
export const ADD_SIGNUP = 'ADD_SIGNUP';
export const CLEAR_SIGNUP_DATA = 'CLEAR_SIGNUP_DATA';


export const SHOW_SUBSCRIPTION = 'SHOW_SUBSCRIPTION';
export const PAYMENT_ADD_VIEW = 'PAYMENT_ADD_VIEW';


export const COMPARE_FILE = 'COMPARE_FILE';
export const GET_COMPARE_FILE = 'GET_COMPARE_FILE';
export const SORT_TRUE = 'SORT_TRUE';
export const SORT_FALSE = 'SORT_FALSE';
export const UPDATE_FILE_LIST = 'UPDATE_FILE_LIST';
export const SIGNATURE_MODAL_VIEW = 'SIGNATURE_MODAL_VIEW';
export const BUTTON_PENDING = 'BUTTON_PENDING';
export const BUTTON_SUCCESS = 'BUTTON_SUCCESS';
export const UPDATE_DOCUMENT_FILE = 'UPDATE_DOCUMENT_FILE';
export const SETTINGS_DATA = 'SETTINGS_DATA';
export const ADD_EXPORT_LIST = 'ADD_EXPORT_LIST';
export const ADD_EXPORT_ALLLIST = 'ADD_EXPORT_ALLLIST';
export const ADD_EXPORT_FILE_FORMAT = 'ADD_EXPORT_FILE_FORMAT';
export const ADD_EXPORT_FILE_TYPE = 'ADD_EXPORT_FILE_TYPE';
export const SHOW_SIGNIN = 'SHOW_SIGNIN';
export const OPEN_CLOSE_LINK_POP = 'OPEN_CLOSE_LINK_POP';
export const GET_COMP_TEMPLATE_LIST = 'GET_COMP_TEMPLATE_LIST';

//User
export const USERS_LIST = 'USERS_LIST';
export const CLEAR_USER_LIST = 'CLEAR_USER_LIST';
export const EDIT_VIEW_USER = 'EDIT_VIEW_USER';
export const ADD_VIEW_USER = 'ADD_VIEW_USER';
export const CLEAR_ADD_VIEW_USER = 'CLEAR_ADD_VIEW_USER';
export const USER_DELETE_MODAL = 'USER_DELETE_MODAL';
export const UPADTE_USER_LIST = 'UPADTE_USER_LIST';
export const ADMIN_ADDED_ROLES = 'ADMIN_ADDED_ROLES';
export const SUBADMIN_ROLES_LIST = 'SUBADMIN_ROLES_LIST';
export const ADD_ROLES_DATA = 'ADD_ROLES_DATA';
export const EDIT_ROLES_DATA = 'EDIT_ROLES_DATA';
export const USERS_ACTIVITY_LIST = 'USERS_ACTIVITY_LIST';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const SELECTED_USERS = 'SELECTED_USERS';
export const ADD_USERGROUP_DATA = 'ADD_USERGROUP_DATA';
export const USER_GROUPS_LIST = 'USER_GROUPS_LIST';
export const USER_TABLE_ATTRIBUTES = 'USER_TABLE_ATTRIBUTES';
export const EDIT_USERGROUP_DATA = 'EDIT_USERGROUP_DATA';
export const SET_CLICKED_ID = 'SET_CLICKED_ID';

//HomePage
export const FEATURE_DOCUMENTS = 'FEATURE_DOCUMENTS';
export const TESTIMONIAL_LISTS = 'TESTIMONIAL_LISTS';

//Product Detail
export const PRODUCT_DETAILS_VIEW = 'PRODUCT_DETAILS_VIEW';
export const RANDOM_DOCUMENTS = 'RANDOM_DOCUMENTS';
export const PACKAGE_DOCUMENT_LISTS = 'PACKAGE_DOCUMENT_LISTS';
export const DOCUMENT_ATTRIBUTES = 'DOCUMENT_ATTRIBUTES';
export const FILE_DETAILS_PAGE = 'FILE_DETAILS_PAGE';

//cart
export const CART_COUNT = 'CART_COUNT';
export const VIEW_CART_DETAILS = 'VIEW_CART_DETAILS';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const CLEAR_CART_DETAILS = 'CLEAR_CART_DETAILS';
export const SETDETAILSMETHOD = 'SETDETAILSMETHOD';
export const OFF_LINE_CART_DATA = 'OFF_LINE_CART_DATA';

//Common
export const VIEW_USER_DETAILS = 'VIEW_USER_DETAILS';
export const CHECTOUT_DETAILS = 'CHECTOUT_DETAILS';
export const PAYMENT_SUCCESS_POPUP = 'PAYMENT_SUCCESS_POPUP';
export const ORDER_VIEW_DATA = 'ORDER_VIEW_DATA';
export const ORDER_PAGINATION = 'ORDER_PAGINATION';
export const SEARCH_PACKAGE_LISTS = 'SEARCH_PACKAGE_LISTS';
export const PACKAGE_LISTS_PAGINATION = 'PACKAGE_LISTS_PAGINATION';
export const PACKAGE_LISTS_SEARCHTEXT = 'PACKAGE_LISTS_SEARCHTEXT';
export const NEW_UPDATED_DOCUMENTS = 'NEW_UPDATED_DOCUMENTS';
export const UPDATED_DOCUMENTS_PAGINATION = 'UPDATED_DOCUMENTS_PAGINATION';
export const UPDATED_DOCUMENTS_SEARCHTEXT = 'UPDATED_DOCUMENTS_SEARCHTEXT';
export const ORDER_DETAILS_DATA = 'ORDER_DETAILS_DATA';
export const PAGE_DETAILS_DATA = 'PAGE_DETAILS_DATA';
export const PAGES_LIST_FOOTER = 'PAGES_LIST_FOOTER';
export const GET_RECENT_UPDATES = 'GET_RECENT_UPDATES';
export const GET_RECENT_FOLDERS = 'GET_RECENT_FOLDERS';
export const PDF_MODAL_VIEW = 'PDF_MODAL_VIEW';
export const PDF_FORM_DATA = 'PDF_FORM_DATA';
export const PDF_EDITMODAL_VIEW = 'PDF_EDITMODAL_VIEW';
export const CLEAR_PDF_FORM_DATA = 'CLEAR_PDF_FORM_DATA';
export const USER_BLOG_LISTS = 'USER_BLOG_LISTS';
export const UPDATE_DOCUMENT_CONTENT = 'UPDATE_DOCUMENT_CONTENT';
export const UPDATE_ADMIN_DOCUMENT_CONTENT = 'UPDATE_ADMIN_DOCUMENT_CONTENT';
export const CLEAR_DOWNLOAD_DOC = 'CLEAR_DOWNLOAD_DOC';
export const GET_FOLDERS_LIST = 'GET_FOLDERS_LIST';
export const PAGE_LOADER_PENDING = 'PAGE_LOADER_PENDING';
export const PAGE_LOADER_SUCCESS = 'PAGE_LOADER_SUCCESS';
export const SEARCH_PAGE_LOADER_PENDING = 'SEARCH_PAGE_LOADER_PENDING';
export const SEARCH_PAGE_LOADER_SUCCESS = 'SEARCH_PAGE_LOADER_SUCCESS';
export const RECENTUPDATES_TABLE_ATTRIBUTES = 'RECENTUPDATES_TABLE_ATTRIBUTES';
export const UPDATE_DOCUMENT_LIST = 'UPDATE_DOCUMENT_LIST';
export const SET_EDITOR_CONTENT = 'SET_EDITOR_CONTENT';
export const SUBSCRIPTION_LISTS_ATTRIBUTES = 'SUBSCRIPTION_LISTS_ATTRIBUTES';
export const SUBSCRIPTION_LISTS_SEARCHTEXT = 'SUBSCRIPTION_LISTS_SEARCHTEXT';
export const SEARCH_SUBSCRIPTION_LISTS = 'SEARCH_SUBSCRIPTION_LISTS';
export const SUBSCRIPTION_DETAILS = 'SUBSCRIPTION_DETAILS';
export const SUBSCRIPTION_DETAILS_ATTRIBUTES = 'SUBSCRIPTION_DETAILS_ATTRIBUTES';
export const SET_SIDEBAR_EXPAND = 'SET_SIDEBAR_EXPAND';
export const FILE_NAME_ADD = 'FILE_NAME_ADD';
export const CLEAR_FILE_DOCS_ADD = 'CLEAR_FILE_DOCS_ADD';
export const UPDATE_RESTORE_CONTENT = 'UPDATE_RESTORE_CONTENT';
export const OPEN_SIGNATURE_MODAL = 'OPEN_SIGNATURE_MODAL';
export const DELETE_MODAL_VIEW = 'DELETE_MODAL_VIEW';
export const SET_HEADER_DROPDOWN = 'SET_HEADER_DROPDOWN';
export const SUBSCRIPTION_DESCRIPTION_MODAL = 'SUBSCRIPTION_DESCRIPTION_MODAL';
export const COM_SET_SIGNATURE_ACTIVETAB = 'COM_SET_SIGNATURE_ACTIVETAB';
export const COM_SET_SIGNATURE_PENCIL_COLOR = 'COM_SET_SIGNATURE_PENCIL_COLOR';
export const COM_SET_SIGNATURE_TEXT_COLOR = 'COM_SET_SIGNATURE_TEXT_COLOR';
export const COM_SET_SIGNATURE_FONT_FAMILY = 'COM_SET_SIGNATURE_FONT_FAMILY';
export const COM_SET_SIGNATURE_PREVIEW_IMAGE = 'COM_SET_SIGNATURE_PREVIEW_IMAGE';
export const COM_SET_SIGNATURE_TEXT_VALUE = 'COM_SET_SIGNATURE_TEXT_VALUE';
export const SET_SEARCHED_VALUE = 'SET_SEARCHED_VALUE';
export const SET_SEARCHED_VALUE_FOR_INPUT = 'SET_SEARCHED_VALUE_FOR_INPUT';
export const SET_TABS_FOR_SUBSCRIPTION = 'SET_TABS_FOR_SUBSCRIPTION';
export const SET_HEART = 'SET_HEART';
export const SET_HEART_UNCHECKC = 'SET_HEART_UNCHECKC';
export const SET_PRICINGFILTER = 'SET_PRICINGFILTER';
export const SET_PACKAGEFILTER = 'SET_PACKAGEFILTER';
export const CLEAR_SUBS_DATA = 'CLEAR_SUBS_DATA';
export const SET_ADMIN_JSON = 'SET_ADMIN_JSON';
export const SET_DOCS_SEARCH_VAL = 'SET_DOCS_SEARCH_VAL';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const CLEAR_SEARCH_VALUE = 'CLEAR_SEARCH_VALUE';



//Sidebar Documents Page
export const DOCUMENT_INFO_SIDEBAR = 'DOCUMENT_INFO_SIDEBAR';
export const DOCUMENT_RECENT_ACTIVITIES = 'DOCUMENT_RECENT_ACTIVITIES';
export const RESTORE_MODAL_VIEW = 'RESTORE_MODAL_VIEW';
export const FILTER_DOCUMENT_VARIABLES = 'FILTER_DOCUMENT_VARIABLES';
export const CLIENT_VARIABLES_LIST = 'CLIENT_VARIABLES_LIST';
export const SYSTEM_VARIABLES_LIST = 'SYSTEM_VARIABLES_LIST';
export const SHOW_DBL_CLICK = 'SHOW_DBL_CLICK';
export const SET_DOC_DBL_VALUE = 'SET_DOC_DBL_VALUE';
export const ST_DBL_CLK_FALSE = 'ST_DBL_CLK_FALSE';
export const SKIP_DISCRIPTION = 'SKIP_DISCRIPTION';
export const SKIP_NOTES = 'SKIP_NOTES';
export const REFERENCE_DOCUMENTS = 'REFERENCE_DOCUMENTS';
export const REFERENCE_DOCUMET_VIEW = 'REFERENCE_DOCUMET_VIEW';
export const CONFORMATION_MODAL = 'CONFORMATION_MODAL';
export const DETAILS_MODAL = 'DETAILS_MODAL';

/* bottom modal */
export const BOTTOM_T_W_AL = 'BOTTOM_T_W_AL';
export const NEW_BOTTOM_MODAL = 'NEW_BOTTOM_MODAL';
export const TOP_POP_ACTIVE_SUBS = 'TOP_POP_ACTIVE_SUBS';
export const TOP_POP_COMP_DETAILS = 'TOP_POP_COMP_DETAILS';
export const DISMISS_SUBS = 'DISMISS_SUBS';
export const DISMISS_COMP_DETAILS = 'DISMISS_COMP_DETAILS';

export const MOVE_FILE_MODEL = "MOVE_FILE_MODEL";
export const ADD_MOVETO_DATA = "ADD_MOVETO_DATA";
export const DOCUMENT_REF_MOD = "DOCUMENT_REF_MOD";
export const CHANGE_DOC_REF_FOR_COPY_TO = "CHANGE_DOC_REF_FOR_COPY_TO";
export const POPUP_BREADCRUMBS = "POPUP_BREADCRUMBS";
export const REPLACE_ALL_POP_DOCS = "REPLACE_ALL_POP_DOCS";
export const MOVE_FOLDERS_LIST = "MOVE_FOLDERS_LIST";
export const CLEAR_MOVE_FILE = "CLEAR_MOVE_FILE";
export const UPDATE_FILES_LIST = "UPDATE_FILES_LIST";
export const UPDATE_DOCUMENT_STATUS = "UPDATE_DOCUMENT_STATUS"
export const UPDATE_FOLDERFILES_LIST = "UPDATE_FOLDERFILES_LIST";
export const CHANGE_DOC_REF_FOR_MOVE = "CHANGE_DOC_REF_FOR_MOVE";
export const GET_USER_FILES_FOR_COPY = "GET_USER_FILES_FOR_COPY";


export const SET_SUB_ACTIVE_TAB = "SET_SUB_ACTIVE_TAB";
export const CLEAR_FORM_DATA = "CLEAR_FORM_DATA";
export const AUTH_MODAL = "AUTH_MODAL";
export const SIGN_IN_MODAL = "SIGN_IN_MODAL";
export const FORGOT_MODAL = "FORGOT_MODAL";
export const OTP_MODAL = "OTP_MODAL";
export const OPT_MODAL = "OPT_MODAL";
export const NEW_PASSWORD_MODAL = "NEW_PASSWORD_MODAL";
export const FORGOT_PWD_USER = 'FORGOT_PWD_USER';
export const SUBSCRIPTION_LIST_VIEW = 'SUBSCRIPTION_LIST_VIEW';
export const SUBSCRIPTION_DETAIL_MODAL = 'SUBSCRIPTION_DETAIL_MODAL';
export const SAMPLE_DOC_DATA = 'SAMPLE_DOC_DATA';
export const FEATURE_ACTIVE_TAB = 'FEATURE_ACTIVE_TAB';
export const LATEST_UPDATED_DOCUMENTS = 'LATEST_UPDATED_DOCUMENTS';
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const SUBSCRIPTION_PAYABLE_DETAILS = 'SUBSCRIPTION_PAYABLE_DETAILS';
export const SUBSCRIPTION_SELECTED_PACKAGE = 'SUBSCRIPTION_SELECTED_PACKAGE';
export const SUBSCRIPTION_SELECTED_PACKAGE_DETAILS = 'SUBSCRIPTION_SELECTED_PACKAGE_DETAILS';
export const SUBSCRIPTION_PLAN_RENEWAL = 'SUBSCRIPTION_PLAN_RENEWAL';
export const DOCUMENT_MANAGEMENT_STATUS = 'DOCUMENT_MANAGEMENT_STATUS';
export const SHOW_TOP_POP_MODAL = 'SHOW_TOP_POP_MODAL';
export const DISMISS_MSG_POP = 'DISMISS_MSG_POP';

export const SELECT_ADDON_PACKAGE = "SELECT_ADDON_PACKAGE";

export const ADD_NEW_SUBSCRIPTION = "ADD_NEW_SUBSCRIPTION";
export const REMOVE_SUBSCRIPTION = "REMOVE_SUBSCRIPTION";
export const CLEAR_ADDED_SUBSCRIPTION = "CLEAR_ADDED_SUBSCRIPTION";

export const SHOW_UPDATE_RENEWAL_MODAL = "SHOW_UPDATE_RENEWAL_MODAL";
export const REMOVE_SUBSCRIPTION_SELECTED_PACKAGE = "REMOVE_SUBSCRIPTION_SELECTED_PACKAGE";
export const READ_MORE_USERCONTENT = "READ_MORE_USERCONTENT";
export const READ_MORE_ALLCONTENT = "READ_MORE_ALLCONTENT";
export const REMOVE_SUBSCRIPTION_PLAN_RENEWAL = "REMOVE_SUBSCRIPTION_PLAN_RENEWAL";
export const PACKAGE_SELECT_CHECKBOX = "PACKAGE_SELECT_CHECKBOX";
export const REQULARITY_ACTIVE_TAB = "REQULARITY_ACTIVE_TAB";

export const COUNTRY_LIST = "COUNTRY_LIST";
export const SUBSCRIPTION_TYPE_LIST = "SUBSCRIPTION_TYPE_LIST";
export const SUBSCRIPTION_CATEGORY_LIST = "SUBSCRIPTION_CATEGORY_LIST";

export const COMPANY_DETAILS_FOR_FREE_POLICY = "COMPANY_DETAILS_FOR_FREE_POLICY";

export const SAMPLE_FILES_LIST = "SAMPLE_FILES_LIST";
export const SAMPLE_DOCUMENT_MODAL = "SAMPLE_DOCUMENT_MODAL";
export const COMPANY_LOGO_UPLOAD = "COMPANY_LOGO_UPLOAD";
export const COMPANY_LOGO_CROP = "COMPANY_LOGO_CROP";
export const COMPANY_LOGO_CROPDATA = "COMPANY_LOGO_CROPDATA";
export const SHOW_NO_DATA = "SHOW_NO_DATA";
export const SUBSCRIPTION_PRICE_DETAILS = "SUBSCRIPTION_PRICE_DETAILS";
export const INITIAL_SUBSCRIPTION_SELECT = "INITIAL_SUBSCRIPTION_SELECT";
export const CLEAR_SELECTED_SUB_PLANS = "CLEAR_SELECTED_SUB_PLANS";
export const SELECT_PRINT_PACK = "SELECT_PRINT_PACK";
export const INSERT_SELECTED_PRICES = "INSERT_SELECTED_PRICES";
export const PAYMENT_INTENT_CONFIRM = "PAYMENT_INTENT_CONFIRM";
export const GET_USER_SUBSCRIPTIONS = "GET_USER_SUBSCRIPTIONS";
export const GET_USER_INVOICE_LIST = "GET_USER_INVOICE_LIST";
export const GET_USER_INVOICE_DETAILS = "GET_USER_INVOICE_DETAILS";
export const CAN_UPDATE_SUBSCRIPTION = "CAN_UPDATE_SUBSCRIPTION";
export const USE_SAVED_CARD = "USE_SAVED_CARD";
export const LATES_VIEWED_DOCUMENTS = "LATES_VIEWED_DOCUMENTS";
export const LATES_VIEWED_MYFILES = 'LATES_VIEWED_MYFILES';
export const NEED_REVIEW_DOCUMENT_LIST = "NEED_REVIEW_DOCUMENT_LIST";
export const UPTODATE_DOCUMENT_LIST = "UPTODATE_DOCUMENT_LIST";
export const ACTIVE_PAGE = "ACTIVE_PAGE";
export const DOCUMENT_ACCESS_MODAL = "DOCUMENT_ACCESS_MODAL";
export const RECENTLY_UPDATED_DOCUMENTS = "RECENTLY_UPDATED_DOCUMENTS";
export const FILTER_SUBSCRIPTION = 'FILTER_SUBSCRIPTION';
export const INVOICE_PAGINATION = 'INVOICE_PAGINATION';



/* Review Modal */
export const REVIEW_MODAL_POPOP = 'REVIEW_MODAL_POPOP';
export const DOCUMENT_REVIEW_DATA = 'DOCUMENT_REVIEW_DATA';
export const DOCUMENT_UPDATE_NOTES_LIST = 'DOCUMENT_UPDATE_NOTES_LIST';
export const GET_CLICKED_FILE = 'GET_CLICKED_FILE';
export const GUIDE_MODAL_POPOP = 'GUIDE_MODAL_POPOP';
export const GET_GUIDE_DATA = 'GET_GUIDE_DATA';
export const DOCUMENT_REPORT_DATA = 'DOCUMENT_REPORT_DATA';
export const REPORT_MODAL_POPUP = 'REPORT_MODAL_POPUP';
export const CHNAGE_UPDATE_DOCUMENT_TAB = 'CHNAGE_UPDATE_DOCUMENT_TAB';
export const SET_TABLE_ATTRIBUTES = 'SET_TABLE_ATTRIBUTES';
export const SHOW_REVIEW_MODAL = 'SHOW_REVIEW_MODAL';
export const SET_REVIEW_DATE = 'SET_REVIEW_DATE';

// Notification
export const TOGGLE_NOTIFICATION_MODAL = 'TOGGLE_NOTIFICATION_MODAL';
export const NOTIFICATION_LIST = 'NOTIFICATION_LIST';
export const OPEN_NOTIFICATION_MODAL = 'OPEN_NOTIFICATION_MODAL';
export const GET_ONE_NOTIFICATION = 'GET_ONE_NOTIFICATION';
export const CHANGE_NOTIFICATION_LIMIT = "CHANGE_NOTIFICATION_LIMIT";

//History
export const USER_HISTORY_LIST = 'USER_HISTORY_LIST';
export const USER_HISTORY_DATE = 'USER_HISTORY_DATE';
export const USER_HISTORY_TYPE = 'USER_HISTORY_TYPE';

//Employee Policy Documents
export const POLICY_DOCUMENT_LIST = 'POLICY_DOCUMENT_LIST';
export const POLICY_DOCUMENT_ADD_DATA = 'POLICY_DOCUMENT_ADD_DATA';
export const POLICY_DOCUMENT_EDIT_DATA = 'POLICY_DOCUMENT_EDIT_DATA';
export const TABLE_DATA_FOR_POLICY = 'TABLE_DATA_FOR_POLICY';
export const SEARCH_EMPLOYEE_POLICY = 'SEARCH_EMPLOYEE_POLICY';
export const GET_CLICKED_ID = 'GET_CLICKED_ID';
export const EMPLOYEE_POLICY_CLEAR_DATA = 'EMPLOYEE_POLICY_CLEAR_DATA';
export const EMPLOYEE_POLICY_DOCUMENT_PREVIEW = 'EMPLOYEE_POLICY_DOCUMENT_PREVIEW';
export const EMPLOYEE_POLICY_PREVIEW_DATA = 'EMPLOYEE_POLICY_PREVIEW_DATA';
export const SHOW_GROUP_POLICY_ASSIGN_MODAL = 'SHOW_GROUP_POLICY_ASSIGN_MODAL';
export const SELETED_POLICY_DOCUMENTS = 'SELETED_POLICY_DOCUMENTS';
export const ADD_POLICY_CONFIRMATION_MODAL = 'ADD_POLICY_CONFIRMATION_MODAL';
export const POLICY_UNREAD_NOTIFY_MODAL = 'POLICY_UNREAD_NOTIFY_MODAL';
export const UPDATED_POLICY_DOCUMENT_LIST = 'UPDATED_POLICY_DOCUMENT_LIST';


//Employees

export const EMPLOYEES_LIST = 'EMPLOYEES_LIST';
export const EMPLOYEE_DATA = 'EMPLOYEE_DATA';
export const EMPLOYEE_TOTAL_DATA = 'EMPLOYEE_TOTAL_DATA';
export const EMPLOYEE_TABLE_ATTRIBUTES = 'EMPLOYEE_TABLE_ATTRIBUTES';
export const CLEAR_EMPLOYEE_DETAILS = 'CLEAR_EMPLOYEE_DETAILS';
export const UPADTE_EMPLOYEE_LIST = 'UPADTE_EMPLOYEE_LIST';
export const INVITE_MODAL = 'INVITE_MODAL';
export const INVITED_LIST = 'INVITED_LIST';
export const SHOW_GROUP_POLICY_EDIT_MODAL = 'SHOW_GROUP_POLICY_EDIT_MODAL';
export const EDIT_DUE_DATE = 'EDIT_DUE_DATE';

/* Document/Employee planSubscription - 17-10-2022 */
export const SELECTED_DOCUMENT_SUBSCRIPTION_PLAN = 'SELECTED_DOCUMENT_SUBSCRIPTION_PLAN';
export const SELECTED_EMPLOYEE_SUBSCRIPTION_PLAN = 'SELECTED_EMPLOYEE_SUBSCRIPTION_PLAN';
export const SUBSCRIPTION_PLAN_PERIOD = 'SUBSCRIPTION_PLAN_PERIOD';
export const SHOW_PLAN_UPDATE_MODAL = 'SHOW_PLAN_UPDATE_MODAL';
export const SHOW_SUBSCRIPTION_CANCEL_MODAL = 'SHOW_SUBSCRIPTION_CANCEL_MODAL';
export const SELECTED_FOLDERS = 'SELECTED_FOLDERS';
export const EXPANDED_FOLDER = 'EXPANDED_FOLDER';

export const COMPANY_CREDETIALS = 'COMPANY_CREDETIALS';
export const SHOW_SUBDOMAIN_NOTIFY = 'SHOW_SUBDOMAIN_NOTIFY';
export const MANUAL_BOTTOM_MODAL = 'MANUAL_BOTTOM_MODAL';
export const STORAGE_DETAILS = 'STORAGE_DETAILS';
export const STORAGE_ALERT = 'STORAGE_ALERT';
export const OFFER_MODAL = 'OFFER_MODAL';
export const OFFER_SLUG = 'OFFER_SLUG';

export const ALL_DOCUMENT_LIST = 'ALL_DOCUMENT_LIST';
export const PUBLIC_POLICY_DOCUMENT = 'PUBLIC_POLICY_DOCUMENT';
export const UPDATE_PUBLIC_POLICY_DOCUMENT = 'UPDATE_PUBLIC_POLICY_DOCUMENT';
export const PUBLIC_POLICY_CONTENT = 'PUBLIC_POLICY_CONTENT';

export const MOBILE_VIEW_MODAL = 'MOBILE_VIEW_MODAL';
export const VIEW_DOCX_MODAL = 'VIEW_DOCX_MODAL';
export const ALL_TEMPLATE_LIST = 'ALL_TEMPLATE_LIST';

export const TOGGLE_PAGE_VIEW = 'TOGGLE_PAGE_VIEW';
export const UPDATE_FOLDER_ICON_COLOR = 'UPDATE_FOLDER_ICON_COLOR';
export const MAIN_PARENT_FOLDER_LIST = 'MAIN_PARENT_FOLDER_LIST';
export const SUB_FOLDER_LIST = 'SUB_FOLDER_LIST';
export const SUBSCRIPTION_EXPIRY_NOTIFY_MODAL = 'SUBSCRIPTION_EXPIRY_NOTIFY_MODAL';

export const GET_CLICKED_MULTIPLE_FILE = 'GET_CLICKED_MULTIPLE_FILE';
export const UPDATE_MULTIPLE_DOCUMENT_LIST = 'UPDATE_MULTIPLE_DOCUMENT_LIST';
export const BULK_REVIEW_MODAL_POPOP = 'BULK_REVIEW_MODAL_POPOP';
export const GET_CLICKED_FILE_BULK = 'GET_CLICKED_FILE_BULK';
export const RESTORE_FILE_MODAL_VIEW = 'RESTORE_FILE_MODAL_VIEW';
export const PREVIEW_VERSION_MODAL_VIEW = 'PREVIEW_VERSION_MODAL_VIEW';
export const CORRECTION_MODAL_VIEW = 'CORRECTION_MODAL_VIEW';
export const CORRECTION_MODAL_VIEW_TYPE = 'CORRECTION_MODAL_VIEW_TYPE';
export const DOCUMENT_SUBSCRIPTION_SELECTION = 'DOCUMENT_SUBSCRIPTION_SELECTION';
export const DOCUMENT_LICENCE_TYPE = 'DOCUMENT_LICENCE_TYPE';
export const HASH_URL = 'HASH_URL';
export const GET_DOC_REF_LIST = 'GET_DOC_REF_LIST';
export const GET_RECENT_UPDATES_NEW = 'GET_RECENT_UPDATES_NEW';
export const RECENTUPDATES_TABLE_ATTRIBUTES_NEW = 'RECENTUPDATES_TABLE_ATTRIBUTES_NEW';
export const UPDATE_SETTINGS_PACKAGE = 'UPDATE_SETTINGS_PACKAGE';
export const PREVIOUS_FILE_CONTENT = 'PREVIOUS_FILE_CONTENT';
export const BRACKET_MODAL_POPOP = 'BRACKET_MODAL_POPOP';
export const UPDATE_MODAL_POPOP = 'UPDATE_MODAL_POPOP';
export const IFRAME_MODAL_POPOP = 'IFRAME_MODAL_POPOP';
export const PDELETE_MODAL_VIEW = 'PDELETE_MODAL_VIEW';
export const PRESTORE_MODAL_VIEW = "PRESTORE_MODAL_VIEW";
export const BIN_TABLE_ATTRIBUTES = 'BIN_TABLE_ATTRIBUTES';
export const BIN_DOCUMENTS_LIST = 'BIN_DOCUMENTS_LIST';
export const CLEAR_BIN_DOCUMENTS_LIST = "CLEAR_BIN_DOCUMENTS_LIST";
export const BIN_UPDATE_DLIST = "BIN_UPDATE_DLIST";
export const LOCK_DOC_USER_MODAL_VIEW = "LOCK_DOC_USER_MODAL_VIEW";
export const BUSSINESSADDRESS_MODAL_VIEW = "BUSSINESSADDRESS_MODAL_VIEW";


/*KNOWLEDGE BASE */
export const GET_CATEGORY_KNOWLEDGE = "GET_CATEGORY_KNOWLEDGE";
export const GET_CATEGORY_KNOWLEDGE_VIDEOLIST = "GET_CATEGORY_KNOWLEDGE_VIDEOLIST";

//NEW
export const GET_TOUCHED_FILE = "GET_TOUCHED_FILE";

//QUESTION & ANSWER
export const QUESTION_MODAL_POPUP = "QUESTION_MODAL_POPUP";
export const ADD_QUESTION_MODAL_POPUP = "ADD_QUESTION_MODAL_POPUP";
export const GET_QUESTION_DATA = "GET_QUESTION_DATA";
export const EDIT_QUESTION = "EDIT_QUESTION";
export const GET_TEST_REQ_DATA = "GET_TEST_REQ_DATA";
export const GET_EMP_POLICY_DATA = "GET_EMP_POLICY_DATA";


//DOC SIGN
export const DOC_SIGN_CONTENT = "DOC_SIGN_CONTENT";
export const DOC_SIGN_MODEL = "DOC_SIGN_MODEL";
export const DOC_SIGN_CROP = "DOC_SIGN_CROP";
export const DOC_SIGN_SIGNATURE_PREVIEW_IMAGE = "DOC_SIGN_SIGNATURE_PREVIEW_IMAGE";
export const DOC_SIGN_CROPDATA = "DOC_SIGN_CROPDATA";
export const DOC_SIGN_PDF = "DOC_SIGN_PDF";
export const DOC_SIGN_RECIPIENTS = "DOC_SIGN_RECIPIENTS";
export const CAN_SHARED_FILE_EDIT = "CAN_SHARED_FILE_EDIT";
export const DOC_SIGN_DOWNLOAD = "DOC_SIGN_DOWNLOAD";
export const DOC_SIGN_RECIPIENT_DETAILS = "DOC_SIGN_RECIPIENT_DETAILS";
export const PURCHASE_MODAL = "PURCHASE_MODAL";

