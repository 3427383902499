import * as actionTypes from './types';
import fetchData, { checkValue, client, RootdomainURL } from '../services';

export const userLogin = (loginData, history, props, path, hashPathName) => {
    let offdocumentCart = ''
    let offCartDoc = 0
    if(localStorage.getItem('offline_cart_document_purchase')){
        offCartDoc = 1;
        offdocumentCart = JSON.parse(localStorage.getItem('offline_cart_document_purchase'));
    }
    if (loginData && !loginData.remember) {
        loginData = { ...loginData, remember: false }
    }
    loginData = { ...loginData, offline_cart_data: props && props.offline_cart && Array.isArray(props.offline_cart) && props.offline_cart.length > 0 ? props.offline_cart : [] ,offdocumentCart:offdocumentCart};
    return (dispatch) => {
        if (sessionStorage.getItem('token')) {
            dispatch({ type: 'AUTHENTICATION', payload: true });
        } else {
        let response = fetchData({ url: '/user/login', method: 'POST', data: loginData });
            dispatch({ type: actionTypes.BUTTON_PENDING });
            response.then(res => {
                dispatch({ type: actionTypes.BUTTON_SUCCESS });
                if (res.status === 1) {
                    localStorage.removeItem('offline_cart_subscription_details');
                    dispatch({ type: actionTypes.OFF_LINE_CART_DATA, payload: [] });
                    client.defaults.headers.common['authorization'] = res.response.authToken
                    dispatch({
                        type: actionTypes.TOP_POP_ACTIVE_SUBS,
                        payload: res.activesubs && String(res.activesubs) === String(true) ? 'true' : String(res.activesubs) === String(false) ? 'false' : '',
                        docUpdateStatus: res.docUpdateStatus,
                        docManagementStatus: res.docManagementStatus,
                        status: res.subscription_status
                    });
                    dispatch({
                        type: actionTypes.TOP_POP_COMP_DETAILS,
                        payload: res.Comp_details ? res.Comp_details : 0
                    });
                    if (res && String(res.activesubs) === 'true') {
                        let value = window.btoa(encodeURIComponent(JSON.stringify(res)));
                        if (res.response && res.response.subdomain && res.response.subdomain && res.response.subdomain !== '' && res.response.subdomain !== undefined) {
                            let URL = '';
                            if (RootdomainURL.includes('localhost')) {
                                URL = `http://${res.response.subdomain}.localhost:3001/company/${hashPathName && checkValue(hashPathName) ? hashPathName : 'dashboard'}?user=` + value;
                            } else {
                                URL = `https://${res.response.subdomain}.${RootdomainURL}/company/${hashPathName && checkValue(hashPathName) ? hashPathName : 'dashboard'}?user=` + value;
                            }
                            window.location.href = URL + "&document="+offCartDoc;
                        } else {
                            let URL = '';
                            if (RootdomainURL.includes('localhost')) {
                                URL = `http://localhost:3001/company/${hashPathName && checkValue(hashPathName) ? hashPathName : 'dashboard'}?user=` + value;
                            } else {
                                URL = `https://${RootdomainURL}/company/${hashPathName && checkValue(hashPathName) ? hashPathName : 'dashboard'}?user=` + value;
                            }
                            window.location.href = URL + "&document="+offCartDoc;
                        }
                    } else if (res.response && res.response.role === 'employee') {
                        let value = window.btoa(encodeURIComponent(JSON.stringify(res.response)));
                        let URL = '';
                        if (res.response && res.response.subdomain && checkValue(res.response.subdomain)) {
                            if (RootdomainURL.includes('localhost')) {
                                URL = `http://${res.response.subdomain}.localhost:3002/employee/policies?employee=` + value;
                            } else {
                                URL = `https://${res.response.subdomain}.${RootdomainURL}/employee/policies?employee=` + value;
                            }
                            window.location.href = URL + "&document="+offCartDoc;
                        } else {
                            dispatch({
                                type: actionTypes.BOTTOM_T_W_AL,
                                payload: { show_hide: true, text: 'Company domain name not available', alignment: "centre" }
                            });
                        }
                    } else {
                        let value = window.btoa(encodeURIComponent(JSON.stringify(res)));
                        if (res.response && res.response.subdomain && res.response.subdomain && res.response.subdomain !== '' && res.response.subdomain !== undefined) {
                            let URL = '';
                            if (RootdomainURL.includes('localhost')) {
                                URL = `http://${res.response.subdomain}.localhost:3001/company?user=` + value;
                            } else {
                                URL = `https://${res.response.subdomain}.${RootdomainURL}/company?user=` + value;
                            }
                            window.location.href = URL + "&document="+offCartDoc;
                        } else {
                            let URL = '';
                            if (RootdomainURL.includes('localhost')) {
                                URL = `http://localhost:3001/company?user=` + value;
                            } else {
                                URL = `https://${RootdomainURL}/company?user=` + value;
                            }
                            window.location.href = URL + "&document="+offCartDoc;
                        }
                    }

                    // if (RootdomainURL.includes('localhost')) {
                    //     window.location.href = `http://${res.response.subDomain}.${RootdomainURL}/company/dashboard`;
                    // } else {
                    //     window.location.href = `https://${res.response.subDomain}.${RootdomainURL}/company/dashboard`;
                    // }
                    // sessionStorage.setItem('token', res.response.authToken);
                    // sessionStorage.setItem('userName', res.response.userName);
                    // sessionStorage.setItem('userId', res.response.userId);
                    // sessionStorage.setItem('userRole', res.response.role);
                    // sessionStorage.setItem('companyUserId', res.response.companyUserId);
                    // sessionStorage.setItem('login_user_id', res.response.login_user_id);
                    // sessionStorage.removeItem("activeClass");
                    // sessionStorage.setItem('user_permission', res.response.permission && res.response.permission.length > 0 ? JSON.stringify(res.response.permission) : JSON.stringify([]));
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'You have logged in successfully', alignment: "centre" }
                    });
                    dispatch({
                        type: actionTypes.AUTH_MODAL,
                    });
                    // if (res.readStatus === true) {
                    //     history.push('/policy-documents');
                    // } else {
                    //     return
                    //     if (path && path === 'home') {
                    //         if (res.response.role && res.response.role === 'user') {
                    //             if (String(res.activesubs) === String(false)) {
                    //                 history.push('/policies-procedures');
                    //             } else {
                    //                 history.push('/');
                    //             }
                    //         } else {
                    //             if (props.location.state && props.location.state.from) {
                    //                 // history.push(props.location.state.from);
                    //                 if (props.location.state.from) {
                    //                     history.push('/policies-procedures');
                    //                 } else if (String(res.activesubs) === String(false)) {
                    //                     history.push('/policies-procedures');
                    //                 } else {
                    //                     history.push('/');
                    //                 }
                    //             } else {
                    //                 if (String(res.activesubs) === String(false)) {
                    //                     history.push('/policies-procedures');
                    //                 } else {
                    //                     history.push('/');
                    //                 }
                    //             }
                    //         }
                    //     }
                    //     else if (path && path === 'cart') {
                    //         history.push({
                    //             pathname: "/cart",
                    //             state: {
                    //                 userId: res.response.userId
                    //             }
                    //         })
                    //         let response = fetchData({ url: '/user/get-cart-details', method: 'POST', data: { 'userId': sessionStorage.getItem("userId") } });
                    //         response.then(res => {
                    //             dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS })
                    //             if (res.status === 1) {
                    //                 dispatch({ type: actionTypes.VIEW_CART_DETAILS, payload: res.response });
                    //             }
                    //         });
                    //     } else {
                    //         history.push('/');
                    //     }
                    // }
                } else {
                    dispatch({
                        type: actionTypes.SHOW_ERROR_MESSAGE,
                        payload: { show: true, message: 'Invalid email or password' }
                    });
                }
            }).catch(err => {
                dispatch({ type: actionTypes.BUTTON_SUCCESS });
                dispatch({
                    type: actionTypes.SHOW_ERROR_MESSAGE,
                    payload: { show: true, message: 'Somthing went wrong' }
                });
            });
        }
    }
}

export const SHow_pop_for_login = val => {
    return {
        type: actionTypes.BOTTOM_T_W_AL,
        payload: { show_hide: true, text: val, alignment: "centre" }
    }
}

export const addLoginData = (loginData) => {
    return {
        type: actionTypes.ADMIN_LOGIN,
        payload: loginData
    }
};

export const showSignup = () => {
    return {
        type: actionTypes.SHOW_SIGNUP,
        payload: true
    }
};

export const forgotPassword = (email, history, props) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/forgotpassword', method: 'POST', data: { email } });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                dispatch({ type: 'AUTHENTICATION', payload: true });
                dispatch({
                    type: actionTypes.SHOW_ERROR_MESSAGE,
                    payload: { show: true, message: 'An OTP will be send to your registered email address' }
                });
                dispatch({ type: actionTypes.OPT_MODAL });
                dispatch({ type: actionTypes.FORGOT_MODAL });
                dispatch({
                    type: actionTypes.FORGOT_PWD_USER,
                    payload: res.user
                })
                // history.push('/signin');
                // if (res.response.role && res.response.role === 'user') {
                //     history.push('/dashboard');
                // } else {
                //     if (props.location.state && props.location.state.from) {
                //         history.push(props.location.state.from);
                //     } else {
                //         history.push('/signin');
                //     }
                // }
            }
            if (res.status === 0) {
                dispatch({
                    type: actionTypes.SHOW_ERROR_MESSAGE,
                    payload: { show: true, message: res.response }
                });
            }
            else {
                dispatch({
                    type: actionTypes.SHOW_ERROR_MESSAGE,
                    payload: { show: true, message: 'An OTP will be send to your registered email address' }
                });
            }
        }).catch(err => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
        });
    }
}
export const companyForgotPassword = (email, props) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/forgotpassword', method: 'POST', data: { email } });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.SHOW_ERROR_MESSAGE,
                    payload: { show: true, message: 'An OTP will be send to your registered email address' }
                });
                props.history.push('/verifyotp');
            }
            if (res.status === 0) {
                dispatch({
                    type: actionTypes.SHOW_ERROR_MESSAGE,
                    payload: { show: true, message: res.response }
                });
            }
            else {
                dispatch({
                    type: actionTypes.SHOW_ERROR_MESSAGE,
                    payload: { show: true, message: 'An OTP will be send to your registered email address' }
                });
            }
        }).catch(err => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
        });
    }
}

export const adminUserLogin = (userDetails) => {
    const location = window.location;
    const urlParams = new URLSearchParams(location.search);
    let employee = urlParams.get("employeePlan");
    let selected_addon = urlParams.get("selected_addon");
    let documentCart = urlParams.get("document");

    var newURL = window.location.href;
    var splitURL = newURL.toString().split("?");
    var splitValue = splitURL[0].split('/').slice(4).join('/');
    let hashPathName = splitValue && checkValue(splitValue) ? splitValue : 'dashboard'
    let value = decodeURIComponent(window.atob(userDetails.user));
    let user = JSON.parse(value)
    return (dispatch) => {
        sessionStorage.setItem('isAuthenticated', true);
        if (String(user.activesubs) === 'true' || String(user.activesubs) === 'false') {
            sessionStorage.setItem('usersessiontoken', JSON.stringify({
                timestamp: new Date(),
                sessiontoken: user.response && user.response.authToken ? user.response.authToken : ''
            }));
            sessionStorage.setItem('token', user.response.authToken);
            sessionStorage.setItem('userName', user.response.userName);
            sessionStorage.setItem('userId', user.response.userId);
            sessionStorage.setItem('userRole', user.response.role);
            sessionStorage.setItem('subdomain', user.response.subdomain);
            (user.response.permission && user.response.permission.length > 0) ? sessionStorage.setItem('user_permission', JSON.stringify(user.response.permission)) : sessionStorage.setItem('user_permission', JSON.stringify([]));
            (user.response && user.response.companyUserId) && sessionStorage.setItem('companyUserId', user.response.companyUserId);
            (user.response && user.response.login_user_id) && sessionStorage.setItem('login_user_id', user.response.login_user_id);
        } else {
            sessionStorage.setItem('usersessiontoken', JSON.stringify({
                timestamp: new Date(),
                sessiontoken: user.authToken
            }));
            sessionStorage.setItem('token', user.authToken);
            sessionStorage.setItem('userName', user.userName);
            sessionStorage.setItem('userId', user.userId);
            sessionStorage.setItem('userRole', user.role);
            sessionStorage.setItem('subdomain', user.subdomain);
            (user.permission && user.permission.length > 0) ? sessionStorage.setItem('user_permission', JSON.stringify(user.permission)) : sessionStorage.setItem('user_permission', JSON.stringify([]));
            (user.companyUserId) && sessionStorage.setItem('companyUserId', user.companyUserId);
            (user.login_user_id) && sessionStorage.setItem('login_user_id', user.login_user_id);
        }
        dispatch({ type: 'AUTHENTICATION', payload: true });
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: 'You have logged in successfully', alignment: "centre" }
        });
        localStorage.removeItem('offline_cart_subscription_details');
        dispatch({ type: actionTypes.OFF_LINE_CART_DATA, payload: [] });
        if ((user && user.response && user.response.type === 'login') || (user && user.type === 'login')) {
            if (user && user.activesubs !== undefined && user.activesubs !== null && String(user.activesubs).length > 0) {
                if (String(user.activesubs) === 'true') {
                    // window.location.href = '/company/dashboard'
                    window.location.href = `/company/${hashPathName}`
                }else if(employee || selected_addon || documentCart === 1){
                    window.location.href = '/cart'
                }
                 else {
                    window.location.href = '/policies-procedures'
                }
            } else {
                //window.location.href = '/company/dashboard'
                window.location.href = `/company/${hashPathName}`
            }
        }else if(employee || selected_addon){
            window.location.href = '/cart'
        } 
        else {
            window.location.href = '/policies-procedures'
        }
        // (user && user.activesubs) ? window.location.href = '/company/dashboard' : window.location.href = '/policies-procedures';
    }
}

export const RESETPASSWOD = (userId, reset, password, history) => {
    return (dispatch) => {
        let response = fetchData({ url: `/user/resetpassword/${userId}/${reset}`, method: 'POST', data: { userId, reset, password } });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: res.response, alignment: "centre" }
                });
                history.push('/signin');
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: res.response, alignment: "centre" }
                });
            }
        })
    }
}

export const clearFormData = () => {
    return {
        type: actionTypes.CLEAR_FORM_DATA
    }
}

export const setActiveTab = (tab) => {
    return {
        type: actionTypes.SET_ACTIVE_TAB,
        payload: tab
    }
}

export const authModal = (path) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.AUTH_MODAL,
            payload: path
        });
        dispatch({
            type: actionTypes.CLEAR_FORM_DATA
        });
    }
}
export const signInModal = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FORGOT_MODAL,
        });
        dispatch({
            type: actionTypes.SIGN_IN_MODAL,
        });
        dispatch({
            type: actionTypes.CLEAR_FORM_DATA
        });
    }
}
export const forgotModal = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FORGOT_MODAL,
        });
        dispatch({
            type: actionTypes.SIGN_IN_MODAL,
        });
        dispatch({
            type: actionTypes.CLEAR_FORM_DATA
        });
    }
}
export const otpModal = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FORGOT_MODAL,
        });
        dispatch({
            type: actionTypes.OTP_MODAL
        });
        dispatch({
            type: actionTypes.CLEAR_FORM_DATA
        });
    }
}
export const newPwdModal = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.NEW_PASSWORD_MODAL,
        });
        dispatch({
            type: actionTypes.OTP_MODAL
        });
        dispatch({
            type: actionTypes.CLEAR_FORM_DATA
        });
    }
}

export const otpSubmit = (otp, user) => {
    return (dispatch) => {
        let otp_number = otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4;
        let userId = user !== "" ? user : "";
        let response = fetchData({ url: `/user/otp/check`, method: 'POST', data: { otp_number, userId } });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.SHOW_ERROR_MESSAGE,
                    payload: { show: true, message: res.response }
                });
                dispatch({ type: actionTypes.NEW_PASSWORD_MODAL })
                dispatch({ type: actionTypes.OPT_MODAL })
            } else {
                dispatch({
                    type: actionTypes.SHOW_ERROR_MESSAGE,
                    payload: { show: true, message: res.response }
                });
            }
        })
    }
}
export const companyOtpSubmit = (otp, user, props) => {
    return (dispatch) => {
        let otp_number = otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4;
        let userId = user !== "" ? user : "";
        let response = fetchData({ url: `/user/otp/check`, method: 'POST', data: { otp_number, userId } });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.SHOW_ERROR_MESSAGE,
                    payload: { show: true, message: res.response }
                });
                props.history.push('/resetpassword')
            } else {
                dispatch({
                    type: actionTypes.SHOW_ERROR_MESSAGE,
                    payload: { show: true, message: res.response }
                });
            }
        })
    }
}

export const createNewPassword = (formData, user) => {
    return (dispatch) => {
        let userId = user !== "" ? user : "";
        let data = {
            password: formData.new_password,
            confirmpassword: formData.new_con_password,
            userId: user !== "" ? user : '',
        }
        let response = fetchData({ url: `/user/resetpassword`, method: 'POST', data: data });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: res.response, alignment: "centre" }
                });
                dispatch({ type: actionTypes.AUTH_MODAL });

            } else {
                dispatch({
                    type: actionTypes.SHOW_ERROR_MESSAGE,
                    payload: { show: true, message: res.response, alignment: "centre" }
                });
            }
        })
    }
}
export const updateNewPassword = (formData, user, props) => {
    return (dispatch) => {
        let data = {
            password: formData.new_password,
            confirmpassword: formData.new_con_password,
            userId: user !== "" ? user : '',
        }
        let response = fetchData({ url: `/user/resetpassword`, method: 'POST', data: data });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: res.response, alignment: "centre" }
                });
                props.history.push('/login')

            } else {
                dispatch({
                    type: actionTypes.SHOW_ERROR_MESSAGE,
                    payload: { show: true, message: res.response, alignment: "centre" }
                });
            }
        })
    }
}

export const getCompanyCredetials = (data, props) => async (dispatch) => {
    let result = await fetchData({ url: `/user/get/company/credetials?domainName=${data}`, method: 'GET' });
    dispatch({ type: actionTypes.PAGE_LOADER_PENDING })
    if (result && result.status === 1) {
        dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS })
        dispatch({
            type: actionTypes.COMPANY_CREDETIALS,
            payload: result.response
        })
    } else {
        dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
        dispatch({
            type: actionTypes.COMPANY_CREDETIALS,
            payload: {}
        })
        // props.history.push('/')
        // if (RootdomainURL.includes('localhost')) {
        //     window.location.href = `http://${RootdomainURL}/`;
        // } else {
        //     window.location.href = `https://${RootdomainURL}/`;
        // }
    }
}

export const setHashPathName = (value) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.HASH_URL,
            payload: value ? value : 'dashboard'
        });
    }
}